import api from './api'
import Axios from 'axios'

export default class Prazo {
    
    static getCancelToken = () => Axios.CancelToken.source()

    static getProdutos = async (cancelToken) => {
        const req = await api.get(`/prazos/produtos`, {
            cancelToken: cancelToken.token
        });
        return req.data
    }

    static getCondominios = async (cancelToken, query, limit) => {
        const req = await api.get(`/condominio`, {
            cancelToken: cancelToken.token, 
            params: { query, limit }
        });
        return req.data
    }

    static alteraPrazoPadrao = async (codigo, prazo) => {
        const req = await api.post(`/prazos/${codigo}`, {
            prazo
        });
        return req.data
    }

    static adicionarExcecao = async (codigo, excecao) => {
        const req = await api.post(`/prazos/excecao/${codigo}`, {
            ...excecao
        });
        return req.data
    }

    static removerExcecao = async (id) => {
        const req = await api.delete(`/prazos/excecao/${id}`);
        return req.data
    }

    static listaExcecao = async (cancel, codigo) => {
        const req = await api.get(`/prazos/excecao/${codigo}`, {
            cancelToken: cancel.token
        });
        return req.data
    }
}