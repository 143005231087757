import React from "react";

const PATTERNS = [
  {
    pattern: /(\w{2})_REG_(\d{4})(\d{2})[^C].C(\d{3})/gi,
    tipo: "Boleto",
    partes: {
      Entregador: 1,
      Ano: 2,
      Mês: 3,
      Tipo: 4,
      Sequencia: 5,
    },
  },
  {
    pattern: /(\w{2})_REG_(\d{4})(\d{2})C.C(\d{3})/gi,
    tipo: "Boleto - acordo",
    partes: {
      Entregador: 1,
      Ano: 2,
      Mês: 3,
      Tipo: 4,
      Sequencia: 5,
    },
  },
  {
    pattern: /^(CIR)_(\d{5})_([^_]*)_?(.*)?_(AR|SIMPLES|REGISTRADO).zip/gi,
    tipo: "Cartas - Circular",
    partes: {
      Tipo: 1,
      "Código condominio": 2,
      Condomínio: 3,
      Assunto: 4,
      Postagem: 5,
    },
  },
  {
    pattern: /^(APR)_(\d{5})(.*)?.zip/gi,
    tipo: "Cartas - Apresentação",
    partes: {
      Tipo: 1,
      "Código condominio": 2,
      Condomínio: 3,
    },
  },
  {
    pattern: /^(ATA)_(\d{5})_(.*)_(AGE|AGO|AGJ)_(\d{2})(\d{2})(\d{4})/gi,
    tipo: "Cartas - Ata",
    partes: {
      Tipo: 1,
      "Código condominio": 2,
      Condomínio: 3,
      Assunto: 4,
    },
  },
  {
    pattern:
      /^(CON)_(\d{5})_(.*)_(AGE|AGO|AGJ)_(\d{2})(\d{2})(\d{4})_(AR|SIMPLES|REGISTRADO)/gi,
    tipo: "Cartas - Convocação",
    partes: {
      Tipo: 1,
      "Código condominio": 2,
      Condomínio: 3,
      Assunto: 4,
      Postagem: 8,
    },
  },
  {
    pattern: /^(\w{2})_(\w+)_(.*)(\d{4})(\d{2})(\w).L(\d{3})/gi,
    tipo: "Boleto - Aluguel",
    partes: {
      Entregador: 1,
      Usuário: 2,
      Ano: 4,
      Mês: 5,
      Tipo: 6,
      Seq: 7,
    },
  },
  {
    pattern: /^CONTRA_(\w{2})_(.{2})/gi,
    tipo: "Contracheque",
    partes: {
      Entregador: 1,
      Sequencia: 2,
    },
  },
  {
    pattern: /^PONTO_(\w{2})_(.{2})/gi,
    tipo: "Ponto",
    partes: {
      Entregador: 1,
      Sequencia: 2,
    },
  },
  {
    pattern: /^CARTAINAD_MENSAL_(\d*).DAD/gi,
    tipo: "Inadimplência - Mensal",
    partes: { sequencial: 1 },
  },
  {
    pattern: /^EXT(\d{4})(\d{2})(A).ZIP$/gi,
    tipo: "Extrato - Analítico",
    partes: { ano: 1, mes: 2 },
  },
  {
    pattern: /^EXT(\d{4})(\d{2})(S).ZIP$/gi,
    tipo: "Extrato - Sintético",
    partes: { ano: 1, mes: 2 },
  },
  {
    pattern: /^EXT_PDF_(\d{5})_(.*).ZIP$/gi,
    tipo: "Extrato - PDF",
    partes: { condominio: 1 },
  },
  {
    pattern: /^(\w{2})_CARTAINADCOND_(\d*).txt/gi,
    tipo: "Inadimplência",
    partes: { imobiliaria: 1 },
  },
  {
    pattern: /^EXP(\d{4})(\d{2}).ZIP$/gi,
    tipo: "Extrato - Proprietário",
    partes: { ano: 1, mes: 2 },
  },
];

const TipoDocumento = (props) => {
  const { children, appendTipoArquivo } = props;
  let tipo = PATTERNS.find((p) => children.match(p.pattern));
  if (!tipo) {
    return <>Tipo não encontrado</>;
  }

  appendTipoArquivo(tipo.tipo);

  const partes = children.split(tipo.pattern);
  const itens = Object.keys(tipo.partes).map(
    (chave) => `${chave} ${partes[tipo.partes[chave]]}`
  );
  const strDesc = itens.join(" - ");

  return <>{`${tipo.tipo} ${strDesc && "(" + strDesc + ")"}`}</>;
};

export default TipoDocumento;
