import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Header, Label, Segment } from 'semantic-ui-react';
import RelatorioCartasApi from '../../Api/relatorio-cartas';
// import RelatorioDocsApi from '../../Api/relatorio-docs';
import './style.css';
const DetalheCartas = props => {

    const [registro, setRegistro] = useState(null);

    const [loading, setLoading] = useState(false);

    const consultarRegistro = async() => {
        try {
            if(props.match.params.id){
                setLoading(true);
                                
                const response = await RelatorioCartasApi.getById(props.match.params.id);

                setRegistro(response);

                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        consultarRegistro();
    }, [props.match])

    return <>
    <div className="comprovante">
    <Segment basic padded loading={loading} >
        <Header as="h2" attached="top" content="Informações sobre a Carta"  />
        {registro &&
        <>
            <Segment>
                    <Header as="h5" attached="top" content="Dados da Remessa" dividing   style={{backgroundColor:'#ffffb3',fontWeigth:'normal'}}/>
                    <br/>
                    <label>Arquivo: {registro.Arquivo}</label>
                    <br/>
                    <br/>
                    <label>Job: {registro.IDJob}.{registro.ID}</label>
                    <br/>
                    <br/>
                    <label>Folhas: {registro.Folhas}</label>    
                    <br/>
                    <br/>
                    <label>Tipo Doc: {registro.TipoDoc}</label>   
            </Segment>
            <Segment>
                    <Header as="h5" attached="top" content="Dados da Carta" dividing   style={{backgroundColor:'#ffffb3',fontWeigth:'normal'}}/>
                    {/* <br/> */}
                    {/* <Label  color="black">Vencimento: {moment(registro.DataVencimento).add(3,'hour').format("DD/MM/YYYY")}</Label> */}
                    {/* &nbsp; */}
                    {/* <Label  color="black">Valor: {registro.BOLValor}</Label> */}
                    {/* <br/> */}
                    <br/>
                    <label><b>Condomínio:</b> {registro.CodCondominio} - {registro.Condominio} </label>
                    <br/>
                    <br/>
                    <label><b>Local:</b> {registro.Local}</label>   
                    <br/>
                    <br/>
                    <label><b>Unidade:</b> {registro.Unidade}</label>   
                    <br/>
                    <br/>
                    <label><b>Nome:</b> {registro.Nome}</label>
                    <br/>
                    <br/>
                    <label><b>Endereço:</b> {registro.Endereco}, <b>CEP:</b> {registro.CEP}</label>
                    <br/>
                    <br/>
                    <label><b>Bairro:</b> {registro.Bairro}, <b>Cidade:</b> {registro.Cidade}, <b>UF:</b> {registro.UF}</label>
                    <br/>
                    <br/>
                    <label><b>E-mail:</b> {registro.eMail}</label>
                    <br/>
                    <br/>
            </Segment>

            <Segment>
                    <Header as="h5" attached="top" content="Dados de Envio" dividing   style={{backgroundColor:'#ffffb3',fontWeigth:'normal'}}/>
                    <br/>
                    {/* <> */}
                    <label >Tipo de Envio: {registro.Entregador === 'EM' ? 'E-mail':registro.Entregador!=="EM" && registro.eMail?'Misto - Papel e E-mail':'Impressão em Papel'}</label>                    
                    {(registro.Entregador === 'EM' || registro.Entregador!=="EM" && registro.eMail) &&
                    <>
                        <br/>
                        <br/>
                        <label>Email: {registro.eMail}</label>
                        <br/>
                        <br/>
                        <label>
                            Enviado: &nbsp;
                            {registro.Sent ?
                            <>
                            {registro.SentData ? registro.SentData + (registro.SentHora ? " as " + registro.SentHora: "") :""}                            
                            </>:"Não"
                            }
                        </label>
                        <br/>
                        <br/>
                        {/* <label>
                            Entregue: &nbsp; 
                            {registro.DvrData?
                            <>
                            {registro.DvrData? registro.DvrData +  " as " + registro.DvrHora :""}
                            {registro.DvrStatus? " - " + registro.DvrStatus:""}
                            {registro.DvrErro? " - " + registro.DvrErro: ""}
                            </>:"Não"
                            }
                        </label>
                        <br/>
                        <br/> */}
                    </>
                }
            </Segment>
            <Segment className="hide-print">
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign="right">
                            <Button content="Imprimir" icon="print" onClick={()=> window.print()} color="black"></Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
        }
    </Segment>
    </div>
    
    </>;
}

export default DetalheCartas;