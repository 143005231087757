import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { Button, Label, Select, Table } from "semantic-ui-react";
import { isArray } from "util";
import Api from "../../Api";
import { baseURL } from "../../Api/api";
import DialogDestinatariosEmails from "./DialogDestinatariosEmails";

const STATUS_OPTIONS = [
  { key: 1, value: "Recebido", text: "Recebido" },
  { key: 2, value: "Cancelado", text: "Cancelado" },
  { key: 2, value: "Produção", text: "Produção" },
  { key: 3, value: "Expedição", text: "Expedição" },
  { key: 4, value: "Expedido", text: "Expedido" },
  { key: 5, value: "Coletado", text: "Coletado" },
];

const colors = {
  Recebido: "black",
  Cancelado: "red",
  Expedido: "green",
  Produção: "blue",
  Expedição: "yellow",
  Coletado: "lightgrey",
};

const colorStatus = (status) => {
  return colors[status] || "black";
};

const LinhaProducao = (props) => {
  const [editando, setEditando] = useState(false);
  const [status, setStatus] = useState(null);
  const { doc, openVisualizacao, openHistorico, openExtrato } = props;

  const [docEmailAberto, seTdocEmailAberto] = useState(null);

  useEffect(() => {
    setEditando(false);
  }, [doc.codigo]);

  useEffect(() => {
    setStatus(doc.status);
    return () => setStatus(null);
  }, [doc.status]);

  const atualizaStatus = async () => {
    await Api.Envio.atualizaStatus(doc.codigo, status);
    doc.status = status;
    setEditando(false);
  };

  const isExtrato = useMemo(() => {
    return ["Extrato - Analítico", "Extrato - Sintético"].some(
      (e) => isArray(doc.tipo) && doc.tipo.includes(e)
    );
  }, [doc]);

  const totalBoleto =
    Array.isArray(doc?.resumo?.boleto) &&
    doc.resumo["boleto"].reduce((acc, atual) => acc + atual.total, 0);
  const totalCartas =
    Array.isArray(doc?.resumo?.cartas) &&
    doc.resumo["cartas"].reduce((acc, atual) => acc + atual.total, 0);
  const totalContracheque = doc?.resumo?.contracheque?.length || 0; 
  //Array.isArray(doc?.resumo?.contracheque) && doc.resumo['contracheque'].reduce((acc, atual) => acc + atual.total, 0)
  const totalContraponto = doc?.resumo?.contraponto?.length || 0;
  const totalInadimplencia =
    Array.isArray(doc?.resumo?.inadimplencia) &&
    doc.resumo["inadimplencia"].reduce((acc, atual) => acc + atual.total, 0);
  const totalExtratos =
    Array.isArray(doc?.resumo?.extrato) &&
    doc.resumo["extrato"].reduce((acc, atual) => acc + atual.total, 0);
  const totalBoletosAluguel =
    Array.isArray(doc?.resumo?.boletoAluguel) &&
    doc.resumo["boletoAluguel"].length;
  const totalEmails =
    Array.isArray(doc?.resumo?.emails) && doc.resumo["emails"].length;
  return (
    <>
      <Table.Row key={doc._id}>
        <Table.Cell>{doc.codigo}</Table.Cell>
        <Table.Cell>
          <Moment format="DD/MM/YYYY HH:mm" add={{ hours: -1 }}>
            {doc.created_at}
          </Moment>
        </Table.Cell>
        <Table.Cell>
          {editando && (
            <>
              <Select
                onChange={(e, { value }) => setStatus(value)}
                value={status}
                options={STATUS_OPTIONS}
              />
              <Button
                icon="save"
                color="black"
                attached="right"
                onClick={atualizaStatus}
              />
            </>
          )}
          {!editando && (
            <>
              <Label color={colorStatus(doc.status)}>{doc.status}</Label>
              <Button
                className="hide-print"
                icon="edit"
                circular
                color="black"
                basic
                onClick={() => setEditando(true)}
              />
            </>
          )}
          {doc.cancelamento_parcial && (
            <Label
              title="Houve cancelamento parcial"
              content="*"
              color="red"
              circular
            />
          )}
          {doc.tipo.includes("Campanha email") && (
            <Button
              icon="mail"
              color="green"
              onClick={() => seTdocEmailAberto(doc)}
              circular
              basic
              title="Destinatários"
            />
          )}
        </Table.Cell>
        <Table.Cell className="hide-print" textAlign="right">
          {!doc.arquivo.includes("diario") && (
            <Button
              href={`${baseURL}/envio/retrieve/${doc.codigo}`}
              color="black"
              basic
              icon="download"
            />
          )}
          {doc.arquivo.includes("diario") && (
            <Button
              href={`${baseURL}envio/retrieve/diario/${doc.codigo}`}
              color="black"
              basic
              icon="download"
            />
          )}
        </Table.Cell>
        <Table.Cell>
          {doc.tipo.map((tipo) => (
            <Label key={tipo} content={tipo} />
          ))}
          {isExtrato && (
            <Button
              icon="file"
              size="mini"
              color="black"
              onClick={() => openExtrato(doc)}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          {/* {isArray(doc?.resumo?.boleto) &&
            [
              ...new Set(
                ...doc?.resumo?.boleto?.map((b) =>
                  b.itens?.map((it) => it.codigoCondominio)
                )
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )} */}
          {/* {isArray(doc?.resumo?.boletoAluguel) &&
            [
              ...new Set(
                doc?.resumo?.boletoAluguel?.map((b) => b.codigoCondominio)
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )} */}

          {isArray(doc?.resumo?.cartas) &&
            [
              ...new Set(
                doc?.resumo?.cartas?.map((b) =>
                  b.codigoCondominio !== "undefined" ? b.codigoCondominio : null
                )
              ),
            ].map((it, i) =>
              it ? (
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
              ) : (
                <></>
              )
            )}

          {isArray(doc?.resumo?.cartasAgrupado) &&
            [
              ...new Set(
                doc?.resumo?.cartasAgrupado?.map((b) => b.codigoCondominio)
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )}

          {isArray(doc?.resumo?.contracheque) &&
            [
              ...new Set(
                doc?.resumo?.contracheque?.map((b) => b.codigoCondominio)
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )}
            
            {isArray(doc?.resumo?.contraponto) &&
            [
              ...new Set(
                doc?.resumo?.contraponto?.map((b) => b.codigoCondominio)
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )}

          {isArray(doc?.resumo?.contrachequeImprime) &&
            [
              ...new Set(
                doc?.resumo?.contrachequeImprime?.map((b) => b.codigoCondominio)
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )}

        {isArray(doc?.resumo?.contrapontoImprime) &&
            [
              ...new Set(
                doc?.resumo?.contrapontoImprime?.map((b) => b.codigoCondominio)
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )}

          {isArray(doc?.resumo?.contrachequeNaoImprime) &&
            [
              ...new Set(
                doc?.resumo?.contrachequeNaoImprime?.map((b) =>
                  b.itens?.map((it) => it.codigoCondominio)
                )
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )}

          {isArray(doc?.resumo?.contrapontoNaoImprime) &&
            [
              ...new Set(
                doc?.resumo?.contrapontoNaoImprime?.map((b) =>
                  b.itens?.map((it) => it.codigoCondominio)
                )
              ),
            ].map(
              (it, i) =>
                (i % 4 === 0 && (
                  <>
                    {" "}
                    <br />
                    <Label key={it} content={it} />
                  </>
                )) || <Label key={it} content={it} />
            )}
        </Table.Cell>
        <Table.Cell>
          {totalBoleto +
            totalCartas +
            totalInadimplencia +
            totalContracheque +
            totalContraponto +
            totalExtratos +
            totalBoletosAluguel +
            totalEmails}
        </Table.Cell>
        <Table.Cell>{doc.usuario && doc.usuario.name}</Table.Cell>
        <Table.Cell>
          {Array.isArray(doc?.resumo?.entregador) &&
            doc.resumo.entregador.map((entregador) => (
              <Label key={entregador} content={entregador} />
            ))}
          {!Array.isArray(doc?.resumo?.entregador) && doc?.resumo?.entregador}
          {doc.cancelamento_parcial && (
            <Label
              title="Houve cancelamento parcial"
              content="*"
              color="red"
              circular
            />
          )}
        </Table.Cell>
        {/* <Table.Cell className="hide-print" textAlign="right">
          {!doc.arquivo.includes("diario") && (
            <Button
              href={`${baseURL}/envio/retrieve/${doc.codigo}`}
              color="black"
              basic
              icon="download"
            />
          )}
          {doc.arquivo.includes("diario") && (
            <Button
              href={`${baseURL}envio/retrieve/diario/${doc.codigo}`}
              color="black"
              basic
              icon="download"
            />
          )}
        </Table.Cell> */}
        <Table.Cell className="hide-print" textAlign="right">
          <Button
            onClick={() => openVisualizacao(doc)}
            color="black"
            basic
            icon="search"
          />
        </Table.Cell>
        <Table.Cell className="hide-print" textAlign="right">
          <Button
            onClick={() => openHistorico(doc)}
            color="black"
            basic
            icon="history"
          />
        </Table.Cell>
      </Table.Row>
      <DialogDestinatariosEmails
        doc={docEmailAberto}
        onClose={() => seTdocEmailAberto(null)}
      />
    </>
  );
};

export default LinhaProducao;
