import React, { useEffect, useState } from "react";
import { Header, Modal, Table } from "semantic-ui-react";
import { isArray } from "util";

const DialogVisualizacao = (props) => {
  const { resumo: doc, onClose } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(doc !== null);
  }, [doc]);

  if (!doc) {
    return <></>;
  }

  const { resumo, codigo } = doc;

  return (
    <Modal size="large" open={open} onClose={onClose}>
      <Modal.Header>
        <Header as="h1" content={`Visualizando resumo do envio ${codigo}`} />
      </Modal.Header>
      <Modal.Content>
        {isArray(resumo["boleto"]) && resumo["boleto"].length > 0 && (
          <>
            <Header>Boletos</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Código Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>Número de boletos</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {resumo["boleto"].map((item) => (
                  <React.Fragment key={item.entregador}>
                    <Table.Row key={item.entregador}>
                      <Table.Cell colSpan={4}>
                        <Header as="h2" color={"orange"}>
                          {item.entregador}
                        </Header>
                      </Table.Cell>
                    </Table.Row>

                    {isArray(item.itens) &&
                      item.itens.map((condominio) => {
                        return (
                          <Table.Row
                            style={{
                              backgroundColor: condominio.cancelado
                                ? "#b0170c"
                                : "",
                            }}
                            key={condominio.codigoCondominio}
                          >
                            <Table.Cell>
                              {condominio.codigoCondominio}
                            </Table.Cell>
                            <Table.Cell>{condominio.nomeCondominio}</Table.Cell>
                            <Table.Cell>
                              {condominio.numeroRegistros}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}

                    <Table.Row>
                      <Table.Cell colSpan={2}>
                        <strong>Total</strong>
                      </Table.Cell>
                      <Table.Cell>
                        <strong>{item.total}</strong>
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                ))}
                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }} colSpan={2}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>
                      {resumo["boleto"].reduce(
                        (acc, atual) => acc + atual.total,
                        0
                      )}
                    </strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}

        {isArray(resumo["cartas"]) && resumo["cartas"].length > 0 && (
          <>
            <Header>Cartas</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Entregador</Table.HeaderCell>
                  <Table.HeaderCell>Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>N. Páginas</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {resumo["cartas"].map((item, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{item.entregador}</Table.Cell>
                    <Table.Cell>{item.codigoCondominio}</Table.Cell>
                    <Table.Cell>{item.numPaginas}</Table.Cell>
                    <Table.Cell>{item.total}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}

        {isArray(resumo["extrato"]) && resumo["extrato"].length > 0 && (
          <>
            <Header>Extrato</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Entregador</Table.HeaderCell>
                  <Table.HeaderCell>Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>Tipo</Table.HeaderCell>
                  <Table.HeaderCell>N. Páginas</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {resumo["extrato"].map((item, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{item.entregador}</Table.Cell>
                    <Table.Cell>{item.codigoCondominio}</Table.Cell>
                    <Table.Cell>{item.tipo}</Table.Cell>
                    <Table.Cell>{item.numPaginas}</Table.Cell>
                    <Table.Cell>{item.total}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}

        {isArray(resumo["boletoAluguel"]) &&
          resumo["boletoAluguel"].length > 0 && (
            <>
              <Header as="h2" content="Boletos - Aluguel" />
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Cod. Imovel</Table.HeaderCell>
                    <Table.HeaderCell>Nome pessoa</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {resumo["boletoAluguel"].map((boleto) => (
                    <Table.Row key={boleto.codImovel}>
                      <Table.Cell>{boleto.codImovel}</Table.Cell>
                      <Table.Cell>{boleto.nomePessoa}</Table.Cell>
                    </Table.Row>
                  ))}

                  <Table.Row>
                    <Table.Cell style={{ backgroundColor: "#CCC" }}>
                      <strong>Total geral</strong>
                    </Table.Cell>
                    <Table.Cell style={{ backgroundColor: "#CCC" }}>
                      <strong>{resumo["boletoAluguel"].length}</strong>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </>
          )}

        {isArray(resumo["inadimplencia"]) &&
          resumo["inadimplencia"].length > 0 && (
            <>
              <Header>Cartas de inadimplência</Header>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Arquivo</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {resumo["inadimplencia"].map((item, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{item.arquivo}</Table.Cell>
                      <Table.Cell>{item.total}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        {isArray(resumo["contracheque"]) && resumo["contracheque"].length > 0 && (
          <>
            <Header>Contracheque</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Imprime ponto</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {resumo["contrachequeImprime"].length > 0 && (
                  <Table.Row key={1}>
                    <Table.Cell>Sim</Table.Cell>
                    <Table.Cell>
                      {resumo["contrachequeImprime"].length}
                    </Table.Cell>
                  </Table.Row>
                )}
                {resumo["contrachequeNaoImprime"].length > 0 && (
                  <Table.Row key={2}>
                    <Table.Cell>Não</Table.Cell>
                    <Table.Cell>
                      {resumo["contrachequeNaoImprime"].length}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        )}
        {isArray(resumo["contraponto"]) && resumo["contraponto"].length > 0 && (
          <>
            <Header>Contra Ponto</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Imprime ponto</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {resumo["contrapontoImprime"].length > 0 && (
                  <Table.Row key={1}>
                    <Table.Cell>Sim</Table.Cell>
                    <Table.Cell>
                      {resumo["contrapontoImprime"].length}
                    </Table.Cell>
                  </Table.Row>
                )}
                {resumo["contrapontoNaoImprime"].length > 0 && (
                  <Table.Row key={2}>
                    <Table.Cell>Não</Table.Cell>
                    <Table.Cell>
                      {resumo["contrapontoNaoImprime"].length}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default DialogVisualizacao;
