import React, { useEffect, useState } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";

const DialogFinalizacao = (props) => {
  const {
    dados: {
      boleto: boletos,
      cartas,
      boletoAluguel,
      contracheque,
      contraponto,
      contrapontoImprime,
      contrapontoNaoImprime,
      inadimplencia,
      extrato,
      entregadores,
      cartasAgrupado,
      agrupador,
      extratoAgrupado,
      contrachequeImprime,
      contrachequeNaoImprime,
    },
    dados,
    open,
    onClose,
    finalizar,
  } = props;

  // const [dados, setDados] = useState([]);

  const [travarBotao, setTravarBotao] = useState(true);

  // const agrupadoEntregador = groupBy(boletos, boleto => boleto.entregador)
  // const entregadores = Object.keys(agrupadoEntregador)

  // const agrupador = entregadores.reduce((acc, entregador) => {
  //     acc[entregador] = groupBy(agrupadoEntregador[entregador], boleto => boleto.codigoCondominio)
  //     return acc
  // }, {})

  // const cartasAgrupado = groupBy(cartas, c => c.condominio);
  // const extratoAgrupado = groupBy(extrato, c => c.condominio);

  // const contrachequeImprime = contracheque.filter(c => c.imprimePonto)
  // const contrachequeNaoImprime = contracheque.filter(c => !c.imprimePonto)

  useEffect(() => {
    if (open) {
      setTravarBotao(true); // Travar botão ok até a finalização da contagem correta dos dados.
      // let dt = entregadores.map(entregador => {
      //     const itens = Object.keys(agrupador[entregador]).map(condominio => {
      //         const grupo = agrupador[entregador][condominio]
      //         return ({
      //             codigoCondominio: grupo[0].codigoCondominio,
      //             nomeCondominio: grupo[0].nomeCondominio,
      //             numeroRegistros: grupo.length,
      //         })
      //     })
      //     const total = Object.values(agrupador[entregador]).reduce((acc, item) => acc + item.length, 0)
      //     return { entregador, itens, total }
      // })

      // const boletosEmailAdicionais = boletos.filter(c => c.entregador !== 'EM' && c.email);

      // if(boletosEmailAdicionais.length > 0) {
      //     const itemBoletosAdicionais = {
      //         entregador: 'EM-AD',
      //         itens: [{
      //             codigoCondominio: '00000',
      //             nomeCondominio: 'EMAIL',
      //             numeroRegistros: boletosEmailAdicionais.length,
      //         }],
      //         total: boletosEmailAdicionais.length
      //     }

      //     dt.push(itemBoletosAdicionais)
      // }

      // const objCartas = []
      // Object.keys(cartasAgrupado).forEach(condominio => {
      //     const carta = cartasAgrupado[condominio]
      //     const groupEntregador = groupBy(carta, c => c.entregador)
      //     const cartasCondo = Object.keys(groupEntregador).map(e => {
      //         const carta = groupEntregador[e];
      //         return {
      //             codigoCondominio: condominio,
      //             entregador: e,
      //             numPaginas: carta[0].numPaginas,
      //             total: carta.length
      //         }
      //     })
      //     objCartas.push(...cartasCondo)
      // })

      // const objExtrato = []
      // Object.keys(extratoAgrupado).forEach(condominio => {
      //     const carta = extratoAgrupado[condominio]
      //     const groupEntregador = groupBy(carta, c => c.entregador)
      //     Object.keys(groupEntregador).forEach(entregador => {
      //         const carta = groupEntregador[entregador];
      //         const groupTipo = groupBy(carta, c => c.tipo)
      //         const cartasCondo = Object.keys(groupTipo).map(e => {
      //             const carta = groupTipo[e];
      //             return {
      //                 codigoCondominio: condominio,
      //                 entregador: entregador,
      //                 total: carta.length,
      //                 tipo: e
      //             }
      //         })
      //         objExtrato.push(...cartasCondo)
      //     })

      // })

      // const entregadoresCartas = groupBy(objCartas, c => c.entregador)
      // const entregador = new Set([...Object.keys(entregadoresCartas), ...Object.keys(agrupadoEntregador)]);

      // let objContracheque = []
      // if(contracheque.length){
      //     objContracheque = [
      //         { imprimePonto: true, total: contrachequeImprime.length },
      //         { imprimePonto: false, total: contrachequeNaoImprime.length }
      //     ]
      // }

      // let objInadimplencia = []
      // if(inadimplencia.length){
      //     objInadimplencia = [...inadimplencia]
      // }

      // setDados({boleto: dt, cartas: objCartas, entregador: [...entregador], contracheque: objContracheque, inadimplencia: objInadimplencia, extrato: objExtrato, boletoAluguel: boletoAluguel});
      setTravarBotao(false); // Destravar botão ok.
    }
  }, [open]);

  if (!open) {
    return <></>;
  }

  //const agrupador = groupBy(boletos, boleto => boleto.codigoCondominio)
  //const condominios = Object.keys(agrupador);

  return (
    <Modal size="large" open={open} closeIcon onClose={onClose}>
      <Modal.Header>
        <Header as="h1" content="Revise os dados antes de enviar" />
      </Modal.Header>
      <Modal.Content>
        {boletos.length > 0 && (
          <>
            <Header as="h2" content="Boletos" />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Código Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>Número de boletos</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {entregadores.map((entregador) => (
                  <React.Fragment key={entregador}>
                    <Table.Row key={entregador}>
                      <Table.Cell colSpan={4}>
                        <Header as="h2" color={"orange"}>
                          {entregador}
                        </Header>
                      </Table.Cell>
                    </Table.Row>

                    {Object.keys(agrupador[entregador]).map((condominio) => {
                      const grupo = agrupador[entregador][condominio];
                      return (
                        <Table.Row key={condominio}>
                          <Table.Cell>{grupo[0].codigoCondominio}</Table.Cell>
                          <Table.Cell>{grupo[0].nomeCondominio}</Table.Cell>
                          <Table.Cell>{grupo.length}</Table.Cell>
                        </Table.Row>
                      );
                    })}

                    <Table.Row>
                      <Table.Cell colSpan={2}>
                        <strong>Total</strong>
                      </Table.Cell>
                      <Table.Cell>
                        <strong>
                          {Object.values(agrupador[entregador]).reduce(
                            (acc, item) => acc + item.length,
                            0
                          )}
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                ))}
                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }} colSpan={2}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>
                      {Object.values(agrupador).reduce(
                        (acc, item) =>
                          acc +
                          Object.values(item).reduce(
                            (ac, i) => ac + i.length,
                            0
                          ),
                        0
                      )}
                    </strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}

        {cartas.length > 0 && (
          <>
            <Header as="h2" content="Cartas" />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Código Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>Número de cartas</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.keys(cartasAgrupado).map((condominio) => (
                  <Table.Row key={condominio}>
                    <Table.Cell>{condominio}</Table.Cell>
                    <Table.Cell>{cartasAgrupado[condominio].length}</Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>
                      {Object.values(cartasAgrupado).reduce(
                        (acc, item) => acc + item.length,
                        0
                      )}
                    </strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}

        {extrato.length > 0 && (
          <>
            <Header as="h2" content="Extratos" />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Código Condomínio</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.keys(cartasAgrupado).map((condominio) => (
                  <Table.Row key={condominio}>
                    <Table.Cell>{condominio}</Table.Cell>
                    <Table.Cell>
                      {extratoAgrupado[condominio].length}
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>
                      {Object.values(extratoAgrupado).reduce(
                        (acc, item) => acc + item.length,
                        0
                      )}
                    </strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}

        {boletoAluguel.length > 0 && (
          <>
            <Header as="h2" content="Boletos - Aluguel" />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Cod. Imovel</Table.HeaderCell>
                  <Table.HeaderCell>Nome pessoa</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {boletoAluguel.map((boleto) => (
                  <Table.Row key={boleto.codImovel}>
                    <Table.Cell>{boleto.codImovel}</Table.Cell>
                    <Table.Cell>{boleto.nomePessoa}</Table.Cell>
                  </Table.Row>
                ))}

                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>{boletoAluguel.length}</strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
        {contracheque.length > 0 && (
          <>
            <Header as="h2" content="Contracheques" />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Imprime ponto</Table.HeaderCell>
                  <Table.HeaderCell>Quantidade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {contrachequeImprime.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Sim</Table.Cell>
                    <Table.Cell>{contrachequeImprime.length}</Table.Cell>
                  </Table.Row>
                )}
                {contrachequeNaoImprime.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Não</Table.Cell>
                    <Table.Cell>{contrachequeNaoImprime.length}</Table.Cell>
                  </Table.Row>
                )}

                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>{contracheque.length}</strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
        {contraponto.length > 0 && (
          <>
            <Header as="h2" content="Contraponto" />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Imprime ponto</Table.HeaderCell>
                  <Table.HeaderCell>Quantidade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {contrapontoImprime.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Sim</Table.Cell>
                    <Table.Cell>{contrapontoImprime.length}</Table.Cell>
                  </Table.Row>
                )}
                {contrapontoNaoImprime.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Não</Table.Cell>
                    <Table.Cell>{contrapontoNaoImprime.length}</Table.Cell>
                  </Table.Row>
                )}

                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>{contraponto.length}</strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
        {inadimplencia.length > 0 && (
          <>
            <Header as="h2" content="Cartas de inadimplência" />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Tipo</Table.HeaderCell>
                  <Table.HeaderCell>Arquivo</Table.HeaderCell>
                  <Table.HeaderCell>Quantidade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {inadimplencia.map((inad, i) => (
                  <Table.Row key={`inad-${i}`}>
                    <Table.Cell>{inad.tipo}</Table.Cell>
                    <Table.Cell>{inad.arquivo}</Table.Cell>
                    <Table.Cell>{inad.total}</Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#CCC" }} colSpan={2}>
                    <strong>Total geral</strong>
                  </Table.Cell>
                  <Table.Cell style={{ backgroundColor: "#CCC" }}>
                    <strong>
                      {inadimplencia.reduce((acc, a) => acc + a.total, 0)}
                    </strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => finalizar(dados)}
          color="black"
          content="Confimar e enviar"
          icon="check circle"
          disabled={travarBotao}
          loading={travarBotao}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DialogFinalizacao;
