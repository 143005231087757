import React, { useState } from 'react'
import { Header, Segment, Divider, Form, Table, Modal, Label } from 'semantic-ui-react'
import Api from '../../Api'
import { DateRangePicker } from "react-dates";
import moment from 'moment'
import { isArray } from 'util';
import { BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'

const VisualizaEnvios = props => {
    const { close, consultor } = props;
    if(!consultor) {
        return <></>
    }

    return (
        <Modal open closeIcon onClose={close} >
            <Modal.Header>
                {consultor.consultor}
            </Modal.Header>
            <Modal.Content>
                { isArray(consultor.condominiosNaoEnviados) && consultor.condominiosNaoEnviados.length > 0 &&
                    <>
                        <Header content="Condomínios não enviados" />
                        { consultor.condominiosNaoEnviados.map(c => <Label style={{marginBottom: 4}} color="red" key={c} content={c} />) }
                    </>
                }
                { isArray(consultor.condominiosEnviados) && consultor.condominiosEnviados.length > 0 &&
                    <>
                        <Header content="Condomínios enviados" />
                        {isArray(consultor.condominiosEnviados) && consultor.condominiosEnviados.map(c => <Label style={{marginBottom: 4}} color="green" key={c} content={c} />)}
                    </>
                }
            </Modal.Content>
        </Modal>
    )
}

const RelatorioEnvios = props => {
    const [data, setData] = useState(null)
    moment.locale('pt-BR');
    const [periodo, setPeriodo] = useState({
        dataInicio: null,
        dataFim: null,
    })
    const [foco, setFoco] = useState(null);
    const [consultor, setConsultor] = useState(null);

    const openVisualizacao = e => {
        if(e) {
            const { activeLabel } = e;
            const consultor = data && data.find(c => c.consultor === activeLabel);
            if (consultor) {
                setConsultor(consultor)
            }
        }
    }

    const closeVisualizacao = () => {
        setConsultor(null);
    }
    
    const load = async () => {
        const cancel = Api.Estatistica.getCancelToken()
        try{
            const p = { dataInicio: moment(periodo.dataInicio).format('YYYY-MM-DD'), dataFim: moment(periodo.dataFim).format('YYYY-MM-DD') }
            const data = await Api.Estatistica.relatorio(p, cancel);
            setData(data);
        } catch(erro){
            setData(null)
            console.log(erro)
        }
    }

    return (
        <>
            <Header as="h2" attached="top" content="Relatório de envios por consultor" dividing />
            <Segment attached="bottom">
                <Form onSubmit={load}>
                    <Form.Group>
                        <Form.Field width={4}>
                            <label>Periodo</label>
                            <DateRangePicker
                                startDate={periodo.dataInicio} // momentPropTypes.momentObj or null,
                                numberOfMonths={2}
                                initialVisibleMonth={() => moment().add(-1, 'month')}
                                minimumNights={0}
                                enableOutsideDays={true}
                                noBorder
                                isOutsideRange={() => false}
                                endDate={periodo.dataFim} // momentPropTypes.momentObj or null,
                                onDatesChange={({ startDate, endDate }) => setPeriodo({ dataInicio: startDate, dataFim: endDate})} // PropTypes.func.isRequired,
                                focusedInput={foco} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput => setFoco(focusedInput || 'startDate')} // PropTypes.func.isRequired,
                                startDatePlaceholderText="Início"
                                endDatePlaceholderText="Fim"
                                startDateId="startDate"
                                endDateId="endDate"
                                onClose={() => setFoco(null)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label style={{opacity: 0}}>Visualizar</label>
                            <Form.Button size="large" color="black" basic content="Buscar dados" icon="search" />
                        </Form.Field>
                    </Form.Group>
                </Form>
                <Divider />
                { data &&
                    <>
                        <ResponsiveContainer height={1000} width="100%">
                            <BarChart  layout="vertical" data={data} onClick={openVisualizacao} height={500}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis width={300} type="category" dataKey="consultor" />
                                <XAxis type="number" />
                                <Tooltip />
                                <Bar dataKey="total" fill="#2a2a3b" />
                                <Bar dataKey="enviados" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Consultor</Table.HeaderCell>
                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                    <Table.HeaderCell>Enviados</Table.HeaderCell>
                                    <Table.HeaderCell>Cobertura</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    isArray(data) && data.map(d => {
                                        return (
                                            <Table.Row key={d.consultor}>
                                                <Table.Cell>{d.consultor}</Table.Cell>
                                                <Table.Cell>{d.total}</Table.Cell>
                                                <Table.Cell>{d.enviados}</Table.Cell>
                                                <Table.Cell>{((d.enviados / d.total) * 100).toFixed(2)}%</Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                    </>
                }
            </Segment>
            <VisualizaEnvios consultor={consultor} close={closeVisualizacao} />
        </>
    )

}

export default RelatorioEnvios