import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import Acompanhamento from "../Components/Acompanhamento/Acompanhamento";
import CampanhasEmail from "../Components/CampanhasEmail/CampanhasEmail";
import CriadorCampanha from "../Components/CampanhasEmail/CriadorCampanha";
import Condominios from "../Components/Condominios/Condomimios";
import CriadorEmail from "../Components/CriadorEmail";
import EntregadoresManuais from "../Components/EntregadoresManuais/EntregadoresManuais";
// import EnvioArquivos from "../Components/EnvioArquivos/EnvioArquivos";
import Estatistica from "../Components/Estatisticas/Estatisticas";
import InicioWrapper from "../Components/Inicio/Inicio";
import Login from "../Components/Login";
import Prazos from "../Components/Prazos/Prazos";
import Producao from "../Components/Producao/Producao";
import DetalheCartas from "../Components/Relatorios/DetalheCartas";
import DetalheDoc from "../Components/Relatorios/DetalheDoc";
import Protocolo from "../Components/Relatorios/Protocolo";
import RelatorioBoletosArquivo from "../Components/Relatorios/RelatorioBoletosArquivo";
import RelatorioConsultaInadimplencia from "../Components/Relatorios/RelatorioConsultaInadimplencia";
import RelatorioEnvios from "../Components/Relatorios/RelatorioEnvios";
import RelatorioGeracaoCartas from "../Components/Relatorios/RelatorioGeracaoCartas";
import RelatorioGeracaoDocs from "../Components/Relatorios/RelatorioGeracaoDocs";
import RelatorioInadimplencia from "../Components/Relatorios/RelatorioInadimplencia";
import RelatorioProducao from "../Components/Relatorios/RelatorioProducao";
import RelatorioProducaoArquivos from "../Components/Relatorios/RelatorioProducaoArquivos";
import Usuarios from "../Components/Usuarios";
import PrivateRoute from "./PrivateRoute";
import Routes from "./routes";

const history = createBrowserHistory();

export default function Navigator() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={Routes.LOGIN} exact component={Login} />
        {/* <PrivateRoute path={Routes.INICIO} fullscreen exact component={EnvioArquivos} /> */}
        <PrivateRoute
          path={Routes.INICIO}
          fullscreen
          exact
          component={InicioWrapper}
        />
        <PrivateRoute
          path={Routes.USUARIOS}
          permissions={["root", "administrador"]}
          exact
          component={Usuarios}
        />
        <PrivateRoute
          path={Routes.ACOMPANHAMENTO}
          exact
          component={Acompanhamento}
        />
        <PrivateRoute
          path={Routes.PRODUCAO}
          permissions={["root", "producao"]}
          fullscreen
          exact
          component={Producao}
        />
        <PrivateRoute
          path={Routes.ESTATISTICAS}
          permissions={["root"]}
          exact
          component={Estatistica}
        />
        <PrivateRoute
          path={Routes.PRAZOS}
          permissions={["root", "administrador"]}
          exact
          component={Prazos}
        />
        <PrivateRoute
          path={Routes.RELATORIO_PRODUCAO}
          permissions={["root", "administrador"]}
          exact
          component={RelatorioProducao}
        />
        <PrivateRoute
          path={Routes.RELATORIO_ENVIOS}
          permissions={["root", "administrador"]}
          exact
          component={RelatorioEnvios}
        />
        <PrivateRoute
          path={Routes.CONDOMINIOS}
          permissions={["root", "administrador"]}
          exact
          component={Condominios}
        />
        <PrivateRoute
          path={Routes.PROTOCOLO}
          permissions={["root", "administrador"]}
          exact
          component={Protocolo}
        />
        <PrivateRoute
          path={Routes.ENTREGADORES_MANUAIS}
          permissions={["root", "administrador"]}
          exact
          component={EntregadoresManuais}
        />
        <PrivateRoute
          path={Routes.RELATORIO_INADIMPLENCIA}
          permissions={["root", "administrador"]}
          exact
          component={RelatorioInadimplencia}
        />
        <PrivateRoute
          path={Routes.RELATORIO_PRODUCAO_ARQUIVOS}
          permissions={["root"]}
          exact
          component={RelatorioProducaoArquivos}
        />
        <PrivateRoute
          path={Routes.CAMPANHAS_EMAIL}
          permissions={["root"]}
          exact
          component={CampanhasEmail}
        />
        <PrivateRoute
          path={Routes.CRIADOR_EMAILS}
          fullscreen
          cover
          exact
          component={CriadorEmail}
        />
        <PrivateRoute
          path={Routes.EDICAO_EMAILS}
          fullscreen
          cover
          exact
          component={CriadorEmail}
        />
        <PrivateRoute
          path={Routes.CRIADOR_CAMPANHA_EMAIL}
          fullscreen
          exact
          component={CriadorCampanha}
        />
        <PrivateRoute
          path={Routes.CONSULTA_INADIMPLENCIAS}
          fullscreen
          exact
          component={RelatorioConsultaInadimplencia}
        />
        <PrivateRoute
          path={Routes.RELATORIO_GERACAO_DOCS}
          fullscreen
          exact
          component={RelatorioGeracaoDocs}
        />
        <PrivateRoute
          path={Routes.RELATORIO_GERACAO_CARTAS}
          fullscreen
          exact
          component={RelatorioGeracaoCartas}
        />
        <PrivateRoute
          path={Routes.DETALHE_DOCS}
          fullscreen
          exact
          component={DetalheDoc}
        />
        <PrivateRoute
          path={Routes.DETALHE_CARTAS}
          fullscreen
          exact
          component={DetalheCartas}
        />
        <PrivateRoute
          path={Routes.RELATORIO_BOLETOS_ARQUIVO}
          permissions={["root", "administrador"]}
          exact
          component={RelatorioBoletosArquivo}
        />
      </Switch>
    </Router>
  );
}
