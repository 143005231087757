import React, { useState } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { isFunction } from 'util'
import Api from '../../Api'
import { MAIN_COLOR } from '../../environment'

const ESTADO_INICIAL = {
    name: '',
    username: '',
    email: '',
    role: '',
    password: '',
    code: '',
    verify_password: '',
}

const optionsRole = [
    { key: 1, text: 'Administrador', value: 'administrador'},
    { key: 2, text: 'Operador', value: 'operador'},
    { key: 3, text: 'Produção', value: 'producao'},
    { key: 4, text: 'Assessor', value: 'assessor'},
]

const FormUsuario = props => {
    const { refresh } = props;

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({...ESTADO_INICIAL})
    const [erro, setErro] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [mensagem, setMensagem] = useState('');

    async function submit(){
        setLoading(true)
        setErro(false);
        setSucesso(false);
        setMensagem('');
        try{
            const dados = {...form}
            validate(dados)
            await Api.Auth.register(dados)
            setSucesso(true)
            setMensagem("Usuário registrado com sucesso")
            isFunction(refresh) && refresh();
        }catch(erro){
            setErro(true)
            if(erro.response && erro.response.data){
                setMensagem(erro.response.data.erro)
            } else {
                setMensagem(erro.message)
            }
        } finally{
            setLoading(false);
        }
    }

    function validate(dados){
        if(!dados.name){
            throw new Error("Nome é obrigatório")
        }
        if(!dados.username){
            throw new Error("Nome de usuário é obrigatório")
        }
        if(!dados.email){
            throw new Error("Nome de usuário é obrigatório")
        }
        if(dados.password && dados.verify_password){
            if(dados.password !== dados.verify_password){
                throw new Error("Senhas informadas não conferem")
            }
        } else {
            throw new Error("Senha é obrigatória")
        }
    }

    function changeForm(e, { name, value }) {
        setForm({ ...form, [name]: value })
    }

    return (
        <Form loading={loading} error={erro} success={sucesso} onSubmit={submit}>
            <Form.Field required>
                <label>Nome</label>
                <Form.Input required name="name" onChange={changeForm} value={form.name} />
            </Form.Field>
            <Form.Group>
                <Form.Field width={10} required>
                    <label style={{display: 'inline'}}>Nome de usuário</label>
                    <small style={{float: 'right'}}>Não deve incluir espaços ou caracteres especiais</small>
                    <Form.Input required name="username" onChange={changeForm} value={form.username} />
                </Form.Field>
                <Form.Field width={6}>
                    <label>Código</label>
                    <Form.Input name="code" onChange={changeForm} value={form.code} />
                </Form.Field>
            </Form.Group>
            <Form.Field required>
                <label>Email</label>
                <Form.Input required name="email" onChange={changeForm} value={form.email} />
            </Form.Field>
            <Form.Field required>
                <label>Função</label>
                <Form.Select required name="role" options={optionsRole} onChange={changeForm} value={form.role} />
            </Form.Field>
            <Form.Group>
                <Form.Field required width={8}>
                    <label>Senha</label>
                    <Form.Input type="password" required name="password" onChange={changeForm} value={form.password} />
                </Form.Field>
                <Form.Field required width={8}>
                    <label>Repita a Senha</label>
                    <Form.Input type="password" required name="verify_password" onChange={changeForm} value={form.verify_password} />
                </Form.Field>
            </Form.Group>
            <Message error icon="times circle" color="red" content={mensagem} />
            <Message success icon="check circle" color="green" content={mensagem} />
            <Form.Button loading={loading} color={MAIN_COLOR} fluid content="Cadastrar usuário" icon="save" />
        </Form>
    )
}

export default FormUsuario