import React, { useState, useEffect, useCallback } from 'react'
import { Table, Dimmer, Loader, Pagination, Dropdown, Menu, Icon } from 'semantic-ui-react'
import Api from '../../Api'
import { isFunction } from 'util';

const ListaUsuarios = props => {
    const { shouldRefresh, refresh } = props;
    const [loading, setLoading] = useState(false);
    const [usuarios, setUsuarios] = useState(null);
    const [pagina, setPagina] = useState(1)
    

    const load = useCallback(async (cancelToken) => {
        setLoading(true);
        try{
            const provs = await Api.Auth.list_all(cancelToken, pagina)
            setUsuarios(provs)
        } catch(error) {

        } finally{
            setLoading(false)
        }
    }, [pagina])

    const disableUser = (id) => async () => {
        try{
            await Api.Auth.disable(id);
            isFunction(refresh) && refresh()
        } catch(erro) {

        }
    }

    const enableUser = (id) => async () => {
        try{
            await Api.Auth.enable(id);
            isFunction(refresh) && refresh()
        } catch(erro) {

        }
    }

    useEffect(() => {
        const cancelToken = Api.Auth.getCancelToken()
        load(cancelToken, pagina)
        return () => cancelToken.cancel()
    }, [pagina, shouldRefresh, load])

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Código</Table.HeaderCell>
                        <Table.HeaderCell>Função</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Ativo?</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign="right">Ações</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { usuarios && usuarios.docs.map(usuario => (
                        <Table.Row key={usuario._id}>
                            <Table.Cell>{usuario.name}</Table.Cell>
                            <Table.Cell>{usuario.username}</Table.Cell>
                            <Table.Cell>{usuario.email}</Table.Cell>
                            <Table.Cell>{usuario.code}</Table.Cell>
                            <Table.Cell>{usuario.role}</Table.Cell>
                            <Table.Cell textAlign="right">
                                {usuario.enabled && <><Icon name="check circle" color="green" />Sim</>}
                                {!usuario.enabled && <><Icon name="times circle" color="red" />Não</>}
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Dropdown icon="bars" >
                                    <Dropdown.Menu>
                                        { usuario.enabled &&
                                            <Menu.Item icon="times" onClick={disableUser(usuario._id)} content="Desativar" />
                                        }
                                        { !usuario.enabled &&
                                            <Menu.Item icon="check" onClick={enableUser(usuario._id)} content="Ativar" />
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={(usuarios && usuarios.totalPages) || 1} defaultActivePage={1} onPageChange={(e,t) => setPagina(t.activePage)} />
            <Dimmer inverted active={loading}>
                <Loader>Carregando...</Loader>
            </Dimmer>
        </>
    )
}

export default ListaUsuarios