import React, { useState } from 'react'
import { Button, Divider, Form, Header, Label, Segment } from 'semantic-ui-react'

const STATUS_OPTIONS = [
    { key: 1, value: 'Recebido', text:'Recebido' },
    { key: 2, value: 'Cancelado', text:'Cancelado' },
    { key: 3, value: 'Produção', text:'Produção' },
    { key: 4, value: 'Expedição', text:'Expedição' },
    { key: 5, value: 'Finalizado', text:'Finalizado' },
    { key: 6, value: 'Expedido', text:'Expedido' },
]

const TIPO_OPTIONS = [
    { key: 1, value: 'Boleto',                  text:'Boleto' },
    { key: 2, value: 'Boleto - acordo',         text:'Boleto - acordo'},
    { key: 3, value: 'Boleto - Aluguel',        text:'Boleto - Aluguel' },
    { key: 4, value: 'Cartas - Apresentação',   text:'Cartas - Apresentação' },
    { key: 5, value: 'Cartas - Ata',            text:'Cartas - Ata' },
    { key: 6, value: 'Cartas - Circular',       text:'Cartas - Circular' },
    { key: 7, value: 'Cartas - Convocação',     text:'Cartas - Convocação' },
    { key: 8, value: 'Contracheque',            text:'Contracheque' },
    { key: 9, value: 'Extrato - Proprietário',  text:'Extrato - Proprietário' },
    { key: 10, value:'Inadimplência',           text:'Inadimplência' },
    { key: 11, value:'Ponto',                   text:'Ponto' },
    // Extrato - Proprietário	
    //Cartas - Apresentação	
]


const Filtros = props => {
    const { applyFilters, filtros: filtrosAplicados,acompanhamento } = props
    const [filtros, setFiltros] = useState({status: '', dataInicio: '', dataFim: ''})
    const [open, setOpen] = useState(false)

    const changeFilters = (e, { name, value }) => {
        setFiltros({...filtros, [name]: value})
    }

    if(!open) {
        return (
            <Header as="h3">
                <Button content="Exibir filtros" icon="filter" floated="right" compact onClick={() => setOpen(true)} color="black" basic />
                <Header.Content>
                    Filtros
                    {filtrosAplicados && Object.keys(filtrosAplicados).filter(f => filtrosAplicados[f]).map(filtro => (
                        <Label basic>
                            {filtro}
                            <Label.Detail>{filtrosAplicados[filtro]}</Label.Detail>
                        </Label>
                    ))}
                </Header.Content>
            </Header>
        )
    }

    return (
        <>
            <Header as="h3">
                <Button content="Ocultar filtros" icon="filter" floated="right" compact onClick={() => setOpen(false)} color="black" basic />
                <Header.Content>
                    Filtros
                </Header.Content>
            </Header>
            <Segment inverted>
                <Form inverted>
                    <Form.Group>
                        <Form.Field width={3}>
                            <label>Data início</label>
                            <Form.Input type="date" name="dataInicio" value={filtros.dataInicio} onChange={changeFilters} />
                        </Form.Field>
                        <Form.Field width={3}>
                            <label>Data Fim</label>
                            <Form.Input type="date" name="dataFim" value={filtros.dataFim} onChange={changeFilters} />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={6}>
                            <label>Status</label>
                            <Form.Select clearable options={STATUS_OPTIONS} name="status" onChange={changeFilters} value={filtros.status} />
                        </Form.Field>
                    </Form.Group>
                    
                        <Form.Group>
                            <Form.Field width={6}>
                                <label>Tipo de Produto</label>
                                <Form.Select clearable options={TIPO_OPTIONS} name="tipo" onChange={changeFilters} value={filtros.tipo} />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group>
                            <Form.Field width={6}>
                                <label>Código</label>
                                <Form.Input clearable  name="codigo" onChange={changeFilters} value={filtros.codigo} />
                            </Form.Field>
                        </Form.Group>

                        {
                        acompanhamento && 
                        <>
                        <Form.Group>
                            <Form.Field width={6}>
                                <label>Operador</label>
                                <Form.Input type="text" name="operador" value={filtros.operador} onChange={changeFilters} />
                            </Form.Field>
                        </Form.Group>
                        </>
                        
                    }
                    <Form.Button color="grey" content="Aplicar filtros" onClick={() => applyFilters(filtros)} fluid />
                </Form>
            </Segment>

            <Divider/>
            
        </>
    )
}

export default Filtros;