import csv from "csvtojson";
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { formatarData } from "../../Helpers/functions";
import routes from "../../Navigator/routes";
import RelatorioDocsApi from "./../../Api/relatorio-docs";
import CsvViewerBoletos from "./CsvViewerBoletos";

const RelatorioGeracaoDocs = (props) => {
  const { history } = props;
  moment.locale("pt-BR");
  const [periodo, setPeriodo] = useState({
    dataInicio: null,
    dataFim: null,
  });

  const [filtros, setFiltros] = useState({ condominio: "" });
  const [foco, setFoco] = useState(null);

  const [registros, setRegistros] = useState(null);

  const [loading, setLoading] = useState(false);

  const [erro, setErro] = useState(null);

  const consultar = async () => {
    try {
      setLoading(true);

      const response = await RelatorioDocsApi.consultar(periodo, filtros);

      setRegistros(response);

      setLoading(false);
    } catch (error) {
      setErro("Ocorreu um erro ao realizar a consulta!");
      setLoading(false);
      setRegistros(null);
    }
  };

  const exportCSV = async () => {
    window.URL = window.webkitURL || window.URL;

    console.log(registros);

    //let regs = registros.replace("_", ". ").replace("_", ". ");
    const jsonData = await csv({ delimiter: ";" }).fromString(registros);

    const dados = [];
    dados.push([
      "IdJob",
      "IdDoc",
      "CodCondominio",
      "Entregador",
      "Condominio",
      "Economia",
      "Pessoa",
      "Vencimento",
      "Email",
      "Enviado",
      "Dt_Envio",
      "Dt_Entrega",
      "Dt_Leitura",
      "Status",
    ]);

    jsonData.map((row) => {
      dados.push([
        row.IdJob,
        row.IdDoc,
        row.CodCondominio,
        row.Entregador,
        row.Condominio,
        row.Economia,
        row.Pessoa,
        moment(row.Vencimento).add(3, "hours").format("DD/MM/YYYY"),
        row.Email,
        row.Enviado,
        (row.Dt_Envio &&
          formatarData(
            row.Dt_Envio,
            "DD/MM/YYYY HH:mm",
            row.DvrStatus,
            "Dt_Envio"
          )) ||
          "",
        (row.Dt_Entrega &&
          formatarData(
            row.Dt_Entrega,
            "DD/MM/YYYY HH:mm",
            row.DvrStatus,
            "Dt_Entrega"
          )) ||
          "",
        (row.Dt_Leitura &&
          formatarData(
            row.Dt_Leitura,
            "DD/MM/YYYY HH:mm",
            row.DvrStatus,
            "Dt_Leitura"
          )) ||
          "",
        row.Status,
      ]);
    });

    var csvFile = new Blob(
      ["\uFEFF" + dados.map((e) => e.join(";")).join("\n")],
      {
        type: "text/csv; charset=utf-8",
      }
    );

    var link = document.createElement("a");

    link.setAttribute("href", window.URL.createObjectURL(csvFile));

    link.setAttribute("download", "relatorio.csv");

    link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");

    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  const navigateToDetails = (doc) => {
    window.open(
      routes.DETALHE_DOCS.replace(":id", doc["IdJob"] + "&" + doc["IdDoc"])
    );
  };

  return (
    <>
      <Header as="h2" attached="top" content="Consulta de Docs" dividing />
      <Segment attached="bottom">
        <Form loading={loading}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Vencimento:</label>
                  <DateRangePicker
                    startDate={periodo.dataInicio} // momentPropTypes.momentObj or null,
                    numberOfMonths={2}
                    minimumNights={0}
                    enableOutsideDays={true}
                    noBorder
                    isOutsideRange={() => false}
                    endDate={periodo.dataFim} // momentPropTypes.momentObj or null,
                    onDatesChange={({ startDate, endDate }) =>
                      setPeriodo({ dataInicio: startDate, dataFim: endDate })
                    } // PropTypes.func.isRequired,
                    focusedInput={foco} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      setFoco(focusedInput || "startDate")
                    } // PropTypes.func.isRequired,
                    startDatePlaceholderText="Início"
                    endDatePlaceholderText="Fim"
                    onClose={() => setFoco(null)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Cod.Condominio:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, condominio: e.target.value })
                    }
                    value={filtros.condominio}
                    placeholder="Código do Condomínio"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Cod.Pessoa:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, pessoa: e.target.value })
                    }
                    value={filtros.pessoa}
                    placeholder="Código da Pessoa"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Cod.Imóvel:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, imovel: e.target.value })
                    }
                    value={filtros.imovel}
                    placeholder="Código do Imóvel"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Nome:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, nome: e.target.value })
                    }
                    value={filtros.nome}
                    placeholder="Nome"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Nome Condomínio:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, nomeCondominio: e.target.value })
                    }
                    value={filtros.nomeCondominio}
                    placeholder="Nome do Condomínio"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Email:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, email: e.target.value })
                    }
                    value={filtros.email}
                    placeholder="E-mail"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Economia:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, economia: e.target.value })
                    }
                    value={filtros.economia}
                    placeholder="Economia"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Pedido:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, pedido: e.target.value })
                    }
                    value={filtros.pedido}
                    placeholder="Código do Pedido"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="right">
                <Button
                  icon="download"
                  content="Relatório"
                  color="black"
                  loading={loading}
                  onClick={() => exportCSV()}
                  type="button"
                />
                <Button
                  icon="search"
                  content="Localizar"
                  color="black"
                  onClick={() => consultar()}
                  loading={loading}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <Divider />
        {erro && <Message error content={erro} icon="times" />}
        {registros && (
          <CsvViewerBoletos
            data={registros}
            hasLink
            linkKey="IdDoc"
            linkDestFn={navigateToDetails}
            formatDate
            dateFormat="DD/MM/YYYY"
            dateFields="Vencimento"
            formatDateTime
            dateTimeFormat="DD/MM/YYYY HH:mm"
            dateTimeFields={["Dt_Envio", "Dt_Entrega", "Dt_Leitura"]}
            showTotal
            invertedDate={["Dt_Envio"]}
            dontShow={["DvrStatus"]}
          />
        )}
      </Segment>
    </>
  );
};

export default RelatorioGeracaoDocs;
