import api from './api'
import Axios from 'axios'

export default class Inadimplencia {
    
    static getCancelToken = () => Axios.CancelToken.source()

    static get_inadimplencia = async (periodo, cancelToken) => {
        const req = await api.get(`/stats`, {
            params:{
                ...periodo
            },
            cancelToken: cancelToken.token
        });
        return req.data
    }

    static relatorio_inadimplencia = async (periodo, cancelToken) => {
        const req = await api.get(`/inadimplencia/relatorio-excel`, {
            params:{ ...periodo },
            cancelToken: cancelToken.token
        });
        return req.data
    }

}