export const LOGIN = 'LOGIN'
export const LOGOUT= 'LOGOUT'

export const LOAD_DEFAULT = 'LOAD_DEFAULT'
export const UNLOAD_DEFAULT = 'UNLOAD_DEFAULT'

export default {
    AUTH: {
        LOGIN,
        LOGOUT
    },
    PROVIDER: {
        LOAD_DEFAULT,
        UNLOAD_DEFAULT
    }
}