import Axios from 'axios'
import CryptoJS from 'crypto-js'
import JSZIP from 'jszip'
import api from './api'

export default class Envio {
    
    static getCancelToken = () => Axios.CancelToken.source()

    static sendFiles = async (arquivos, resumo, tipo) => {
        const zip = new JSZIP()
        arquivos.forEach(arquivo => zip.file(arquivo.name, arquivo))

        const data = await zip.generateAsync({type : "uint8array", compression: "DEFLATE"});
        
        //const dataBase64 = data.toString('base64');
        const WordArray = CryptoJS.lib.WordArray.create(data);
        const hash = CryptoJS.MD5(WordArray).toString(CryptoJS.enc.Hex)
        const reqHash = await api.post('/envio/pre', {
            checksum: hash
        })

        const { token, codigo } = reqHash.data

        const criptografado = CryptoJS.AES.encrypt(WordArray, token).toString()

        const envioArquivo = {
            codigo,
            resumo,
            tipo,
            arquivo: criptografado,
        }
        console.log(envioArquivo)

        const reqArquivo = await api.post('/envio/', envioArquivo)
        await api.get(`/info/build/${codigo}`)
        return reqArquivo.data
        // return {status: 'ok'}

    }

    static list = async (cancelToken, page, filtros) => {
        const req = await api.get('/envio/',{
            cancelToken: cancelToken.token,
            params: {
                page,
                ...filtros
            }
        })
        console.log(req.data.docs[0])
        return req.data
    }

    static export = async (cancelToken, filtros) => {
        const req = await api.get('/envio/export',{
            cancelToken: cancelToken.token,
            params: {                
                ...filtros
            }
        })

        const csvContent = req.data;
        
        window.URL = window.webkitURL || window.URL;

        var csvFile = new Blob(["\uFEFF"+csvContent], {
            type: 'text/csv; charset=utf-8'
        });

        var link = document.createElement("a");

        link.setAttribute("href", window.URL.createObjectURL(csvFile));

        link.setAttribute("download", "producao.csv"); 

        link.dataset.downloadurl = ['text/csv', link.download, link.href].join(':');
        
        document.body.appendChild(link);

        link.click();
    }

    static atualizaStatus = async (codigo, status) => {
        const req = await api.put(`/envio/${codigo}/status`, { status })
        return req.data
    }

    static cancelaCondominio = async (cancelamento) => {
        const req = await api.post(`/envio/cancelamento`, cancelamento)
        return req.data
    }

    static paginasExtrato = async (codigo, resumo) => {
        const req = await api.post(`/envio/extrato_paginas/${codigo}`, resumo)
        return req.data
    }

}