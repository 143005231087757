import React, { useState, useEffect } from 'react'
import { Header, Segment, Form, Table } from 'semantic-ui-react'
import Api from '../../Api'
import useDebounce from '../../Helpers/useDebounce'


const Condominios = props => {
    const [condominios, setCondominios] = useState([])
    const [query, setQuery] = useState('')
    
    const load = async (cancel, query) => {
        try{
            const data = await Api.Prazo.getCondominios(cancel, query);
            setCondominios(data);
        } catch(erro){
            setCondominios([])
            console.log(erro)
        }
    }

    const searchDebounce = useDebounce(query, 1000);

    useEffect(() => {
        const cancel = Api.Estatistica.getCancelToken()
        load(cancel, searchDebounce)
        return () => {
            cancel.cancel()
        };
    }, [searchDebounce])

    return (
        <>
            <Header as="h2" attached="top" content="Condomínios" dividing />
            <Segment attached="bottom">
                <Form.Input size="huge" placeholder="Buscar condomínios" fluid icon="search" iconPosition="right" value={query} onChange={(e, {value}) => setQuery(value)} />
                {
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2}>Código</Table.HeaderCell>
                                <Table.HeaderCell width={5}>Nome</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Vencimento</Table.HeaderCell>
                                <Table.HeaderCell>Consultor</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                condominios.map(c => <Table.Row key={c._id}>
                                    <Table.Cell>{c.codigo}</Table.Cell>
                                    <Table.Cell>{c.nome}</Table.Cell>
                                    <Table.Cell>{c.vencimento}</Table.Cell>
                                    <Table.Cell>{c.consultor}</Table.Cell>
                                </Table.Row>)
                            }
                        </Table.Body>
                    </Table>
                }
            </Segment>
        </>
    )

}

export default Condominios