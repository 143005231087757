import React from 'react'
import Moment from 'react-moment'
import { List } from 'semantic-ui-react'


const STATUS_OPTIONS = [
    { key: 1, value: 'Recebido', icon:'upload' },
    { key: 2, value: 'Cancelado', icon:'times circle', color:"red" },
    { key: 3, value: 'Produção', icon:'print', },
    { key: 4, value: 'Expedição', icon:'folder' },
    { key: 5, value: 'Finalizado', icon:'check' },
    { key: 6, value: 'Expedido', icon:'send', color: 'green' },
    { key: 7, value: 'Coletado', icon:'send', color: 'green' },
]

const IconStatus = ({status, ...others}) => {
    const {icon, color} = STATUS_OPTIONS.find(s => s.value === status)
    return <List.Icon name={icon} color={color} {...others} />
}

const ItemHistorico = props => {
    const { children: doc } = props

    return (
        <List.Item>
            
            <List.Content floated="right">
                <Moment format="DD/MM/YYYY HH:mm:ss">{doc.data}</Moment>
            </List.Content>
            <IconStatus size="large" status={doc.status} />
            <List.Content>
                <List.Header as="h2">{doc.status}</List.Header>
                { doc.operador && <List.Description>Operador: {doc.operador.name} ({doc.operador.role})</List.Description> }
            </List.Content>
        </List.Item>
    )
}

export default ItemHistorico