const regexLines = {
    '00': {},
    '01': {},
    '02': { 
        pattern: /^(\d{2})(.{5})(.{3})(.{25})(.{40})(.{100})(.{10})(.{8})(.{7})(.{40})(.{1})(.{14})(.{7})(.{40})(.{8})(.{8})(\w{1})(.{6})((.{10})(.{10})(.{10})(.{10}))?$/gi,
        partes: {
            codigoCondominio: 2,
            codigoBloco: 3,
            codigoEconomia: 4,
            nomeCondominio: 5,
            endereco: 6,
            tipoImovel: 7,
            codImovel: 8,
            codPessoa: 9,
            nomePessoa: 10,
            tipoPessoaProp: 11,
            cpfCnpjProp: 12,
            codProp: 13,
            nomeProp: 14,
            dataVencFianca: 15,
            dataReajAluguel: 16,
            tipoDoc: 17,
            competencia: 18,
            periodoRefIni: 20,
            periodoRefFim: 21,
            vigIncendioIni: 22,
            vigIncendioFim: 23,
        }
    }
}

class BoletoAluguel{
    file = null
    constructor(filename, file) {
        this.file = file
        this.filename = filename
    }

    async parse(){
        const lines = this.file.split('\n');
        const lines2 = lines.filter(l => l.startsWith('02'))
        const arr = []
        lines2.map(line => {
            const { partes } =regexLines['02']
            const grps = line.split(regexLines['02'].pattern)
            if(grps && grps.length){
                const obj = Object.keys(partes).reduce((acc, chave) => {
                    return {...acc, [chave]: grps[partes[chave]] && grps[partes[chave]].trim()}
                }, {})
                obj.entregador = this.entregador
                arr.push(obj)
            }
            return null
        })
        return arr.filter(a => a)
    }

    _parseLine(tipo, data){

    }
}

export default BoletoAluguel