import api from './api'
import Axios from 'axios'

export default class Prazo {
    
    static getCancelToken = () => Axios.CancelToken.source()

    static getRegras = async (cancelToken) => {
        const req = await api.get(`/entrega-manual`, {
            cancelToken: cancelToken.token
        });
        return req.data
    }

    static adicionarRegra = async (regra) => {
        const req = await api.post('/entrega-manual', regra);
        return req.data
    }

    static removerRegra = async (id) => {
        const req = await api.delete(`/entrega-manual/${id}`);
        return req.data
    }
}