import React from 'react';
import RelatorioGeracaoDocs from '../Relatorios/RelatorioGeracaoDocs';
import EnvioArquivos from "./../EnvioArquivos/EnvioArquivos";


const InicioWrapper = props => {
    const {currentRole} = props;

    
    return <>
        {
            currentRole === 'assessor'?<RelatorioGeracaoDocs {...props}/>:<EnvioArquivos {...props}/>
        }
    </>
}

export default InicioWrapper;