import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import { Divider, Form, Header, Segment } from "semantic-ui-react";
import Api from "../../Api";
import CsvViewer from "./CsvViewer";
const RelatorioBoletosArquivo = (props) => {
  const [csvData, setCsvData] = useState(null);

  moment.locale("pt-BR");

  const [periodo, setPeriodo] = useState({
    dataInicio: "",
    dataFim: "",
  });
  const [foco, setFoco] = useState(null);

  const load = async () => {
    const cancel = Api.Estatistica.getCancelToken();
    try {
      const p = {
        dataInicio: moment(periodo.dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(periodo.dataFim).format("YYYY-MM-DD"),
      };
      const data = await Api.Producao.relatorio_boletos(p, cancel);
      setCsvData(data);
    } catch (erro) {
      setCsvData(null);
      console.log(erro);
    }
  };

  const exportCSV = async () => {
    window.URL = window.webkitURL || window.URL;

    var csvFile = new Blob(["\uFEFF" + csvData], {
      type: "text/csv; charset=utf-8",
    });

    var link = document.createElement("a");

    link.setAttribute("href", window.URL.createObjectURL(csvFile));

    link.setAttribute("download", "relatorio.csv");

    link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");

    document.body.appendChild(link);

    link.click();
    link.remove();
  };
  return (
    <>
      <Header
        as="h2"
        attached="top"
        content="Relatório de Boletos por Arquivo"
        dividing
      />
      <Segment attached="bottom">
        <Form onSubmit={load}>
          <Form.Group>
            <Form.Field width={4}>
              <label>Periodo</label>
              <DateRangePicker
                startDate={periodo.dataInicio} // momentPropTypes.momentObj or null,
                numberOfMonths={2}
                minimumNights={0}
                enableOutsideDays={true}
                noBorder
                isOutsideRange={() => false}
                endDate={periodo.dataFim} // momentPropTypes.momentObj or null,
                onDatesChange={({ startDate, endDate }) =>
                  setPeriodo({ dataInicio: startDate, dataFim: endDate })
                } // PropTypes.func.isRequired,
                focusedInput={foco} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  setFoco(focusedInput || "startDate")
                } // PropTypes.func.isRequired,
                startDatePlaceholderText="Início"
                endDatePlaceholderText="Fim"
                onClose={() => setFoco(null)}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ opacity: 0 }}>Visualizar</label>
              <Form.Button
                size="large"
                color="black"
                basic
                content="Buscar dados"
                icon="search"
              />
            </Form.Field>
            {csvData && (
              <Form.Field>
                <label style={{ opacity: 0 }}>Baixar</label>
                <Form.Button
                  onClick={exportCSV}
                  size="large"
                  color="black"
                  content="Baixar CSV"
                  icon="download"
                />
              </Form.Field>
            )}
          </Form.Group>
        </Form>
        <Divider />
        {csvData && <CsvViewer data={csvData} />}
      </Segment>
    </>
  );
};

export default RelatorioBoletosArquivo;
