import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import Navigator from './Navigator';
import { Provider } from 'react-redux'
import "moment/locale/pt-br";
import store from './Store';

function App() {
  return (
    <Provider store={store}>
      <Navigator />
    </Provider>
  );
}

export default App;
