import React, { useState, useEffect, useCallback } from 'react'
import { Header, Segment, Dimmer, Loader, Button, Divider, Form, Icon, Table } from 'semantic-ui-react'
import Api from '../../Api';
import AutoCompleteCondominio from './AutoCompleteCondominio';

const Produto = props => {
    const { produto } = props;
    const [show, setShow] = useState(false);
    const [prazo, setPrazo] = useState('');

    const [loadingExcecoes, setLoadingExcecoes] = useState(false);

    const [excecao, setExcecao] = useState({
        condominio: '',
        prazo: ''
    })

    const [excecoes, setExcecoes] = useState([]);

    useEffect(() => {
        setPrazo(produto.prazoPadrao)
    }, [produto.prazoPadrao])

    const changePrazo = (e, {name,value}) => {
        setPrazo(value)
    }

    const changeExcecao = (e, {name,value}) => {
        setExcecao({...excecao, [name]: value})
    }

    const addExcecao = async () => {
        try{
            const add = await Api.Prazo.adicionarExcecao(produto.codigo, excecao)
            setExcecoes([...excecoes, add])
            setExcecao({
                condominio: '',
                prazo: '',
            })
        } catch(erro){

        }
    }

    const removerExcecao = (excecao) => {
        Api.Prazo.removerExcecao(excecao._id).then(result => {
            setExcecoes(excecoes.filter(e => e.condominio !== excecao.condominio))
        }).catch(erro => {

        })
    }

    const listaExcecao = useCallback(async (cancel) => {
        setLoadingExcecoes(true)
        try {
            const lista = await Api.Prazo.listaExcecao(cancel, produto.codigo)
            setExcecoes(lista);
        } catch(error) {
            setExcecoes([])
        }finally{
            setLoadingExcecoes(false)
        }
    }, [produto.codigo])

    useEffect(() => {
        const cancel = Api.Prazo.getCancelToken()
        if (show) { // Carrega dados de exceções
            listaExcecao(cancel)
        }
        return () => {
            cancel.cancel()
        };
    }, [show, produto.codigo, listaExcecao])

    const alteraPrazoPadrao = async () => {
        try {
            await Api.Prazo.alteraPrazoPadrao(produto.codigo, prazo)
        } catch(error){
            console.log(error)
        }
    }


    return (
        <Segment>
            <span onClick={() => setShow(!show)} style={{cursor:'pointer'}}>
                <Header as="h4">
                    <span style={{float: 'right'}}>
                        { !show && <Icon name="angle down" /> }
                        { show && <Icon name="angle up" /> }
                    </span>
                <Header.Content>
                    {produto.nome}
                </Header.Content>
                </Header>
            </span>
            { show &&
                <>
                    <Divider/>
                    <Form onSubmit={alteraPrazoPadrao}>
                        <Form.Group>
                            <Form.Field width={3} >
                                <label>Prazo padrão</label>
                                <Form.Input value={prazo} onChange={changePrazo} size="large" fluid></Form.Input>
                            </Form.Field>
                            <Form.Field>
                                <label style={{visibility: 'hidden'}}>Salvar</label>
                                <Button icon="save" size="large" content="Salvar prazo" basic />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <Divider />
                    <Header as="h5"  content="Exceções"/>
                    <Form onSubmit={addExcecao}>
                        <Form.Group>
                            <Form.Field width={4}>
                                <label>Condominio</label>
                                <AutoCompleteCondominio name="condominio" required value={excecao.condominio} onChange={changeExcecao} />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>Prazo (em dias)</label>
                                <Form.Input name="prazo" required value={excecao.prazo} onChange={changeExcecao}></Form.Input>
                            </Form.Field>
                            <Form.Field width={3}>
                                <label style={{visibility: 'hidden'}}>Adicionar</label>
                                <Form.Button icon="circle add" content="Adicionar"/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}>Condominio</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Nome</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Prazo</Table.HeaderCell>
                                <Table.HeaderCell textAlign="right">Remover</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { excecoes.map((excecao) => 
                                    <Table.Row key={excecao.condominio}>
                                        <Table.Cell>{excecao.condominio.codigo}</Table.Cell>
                                        <Table.Cell>{excecao.condominio.nome}</Table.Cell>
                                        <Table.Cell>{excecao.prazo} dias</Table.Cell>
                                        <Table.Cell textAlign="right">
                                            <Button icon="circle times" basic content="Remover" onClick={() => removerExcecao(excecao)}/>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </>
            }
            <Dimmer active={loadingExcecoes} inverted>
                <Loader>Carregando exceções</Loader>
            </Dimmer>
        </Segment>
    )
}

export default Produto