/*eslint no-sequences: "ignore"*/

import moment from "moment";

export function groupBy(xs, f) {
  return xs.reduce(
    (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  );
}

export function mapArrayToParts(partes, grupos) {
  if (grupos && grupos.length) {
    return Object.keys(partes).reduce((acc, chave) => {
      return {
        ...acc,
        [chave]: grupos[partes[chave]] && grupos[partes[chave]].trim(),
      };
    }, {});
  }
  return null;
}

export function formatarData(date, format, inverted, field) {
  let formato = "";
  if (field === "Dt_Entrega") {
    if (inverted.includes("SendGrid")) {
      formato = moment(date.replace(" as", ""), "MM/DD/YYYY HH:mm").format(
        format
      );
    } else {
      formato = moment(date.replace(" as", ""), "DD/MM/YYYY HH:mm").format(
        format
      );
    }
  } else {
    if (inverted.includes("SendGrid")) {
      formato = moment(date.replace(" as", ""), "MM/DD/YYYY HH:mm").format(
        format
      );
    } else {
      formato = moment(date.replace(" as", ""), "MM/DD/YYYY HH:mm").format(
        format
      );
    }
  }
  console.log(formato);

  return formato;
}
