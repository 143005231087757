import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Header, Form, Table } from 'semantic-ui-react';
import csvtojsonV2 from 'csvtojson/v2';
import { isArray } from 'util'
import Api from '../../Api';

const EnvioPaginasExtrato = props => {
    const { envio, close } = props;
    const [regs, setRegs] = useState([])
    const [loading, setLoading] = useState(false);

    const resumo = useMemo(() => {
        if(!envio) return null
        const {resumo: {extrato}} = envio;
        const res = []
        regs.forEach(registro => {
            const { tipo, condominio, numPaginas } = registro
            const tipoExtrato = tipo === 'EAN' ? 'Extrato - Analítico' : 'Extrato - Sintético';
            const codigoCondominio = condominio.padStart(5, '0')
            const numeroPaginas = +numPaginas;

            const registrosExtrato = extrato.filter(e => e.codigoCondominio === codigoCondominio && e.tipo === tipoExtrato)
            registrosExtrato.forEach(registro => {
                res.push({...registro, numPaginas: numeroPaginas})
            })

            res.sort((a,b) => a.codigoCondominio - b.codigoCondominio)
        })
        return res
    }, [regs, envio])

    useEffect(() => {
        return () => {
            setRegs([])
        };
    }, [envio])

    if(!envio) {
        return <></>
    }

    const onChangeFile = async (e) => {
        if(e.target.files){
            const reader = new FileReader()
            reader.onloadend = async (e) => {
                try{
                    const regs = await csvtojsonV2({
                        delimiter: [';', ','],
                        noheader: true,
                        headers: ['tipo', 'condominio', 'numPaginas']
                    }).fromString(e.target.result)
                    if (isArray(regs)) {
                        console.log(regs)
                        setRegs(regs)
                    }
                } catch(erro){
                    console.log(erro)
                }
            }
            reader.readAsText(e.target.files[0])
        }
    }

    const submit = async () => {
        try{
            setLoading(true)
            const ret = await Api.Envio.paginasExtrato(envio.codigo, { resumo })
            if(ret){
                close()
            }
        } catch(error){
            
        } finally{
            setLoading(false)
        }
    }

    return (
        <Modal open closeIcon onClose={close}>
            <Modal.Header>
                <Header content={`Adicionar número de páginas aos extratos envio ${envio.codigo}`} />
            </Modal.Header>
            <Modal.Content>
                <Form onSubmit={submit}>
                    <Form.Field>
                        <Form.Input accept=".csv" onChange={onChangeFile} type="file" />
                    </Form.Field>
                    <Form.Button disabled={loading || !resumo || resumo.length === 0} loading={loading} content="Enviar" color="black" icon="check circle" fluid />
                </Form>
                {isArray(resumo) && 
                    <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Entregador</Table.HeaderCell>
                            <Table.HeaderCell>Condomínio</Table.HeaderCell>
                            <Table.HeaderCell>Tipo</Table.HeaderCell>
                            <Table.HeaderCell>N. Páginas</Table.HeaderCell>
                            <Table.HeaderCell>Total</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {resumo.map((item, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{item.entregador}</Table.Cell>
                                <Table.Cell>{item.codigoCondominio}</Table.Cell>
                                <Table.Cell>{item.tipo}</Table.Cell>
                                <Table.Cell>{item.numPaginas}</Table.Cell>
                                <Table.Cell>{item.total}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                }
            </Modal.Content>
        </Modal>
    )
}

export default EnvioPaginasExtrato