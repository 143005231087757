import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
  Table
} from "semantic-ui-react";
import styled from "styled-components";
import Api from "../../Api";
import { groupBy } from "../../Helpers/functions";
import MultiParser from "../../Parsers";
import Resumo from "../Resumo/Resumo";
import DialogFinalizacao from "./DialogFinalização";
import TipoDocumento from "./TipoDocumento";

const Wrapper = styled.div`
  background-color: #d3cbbd;
  width: 100vw;
  height: calc(100vh - 2em);
  overflow: auto;
  padding: 3em;
`;

const Logo = styled.div`
  background: url("${(props) => props.src}");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 15vh;
  margin: 5em 0;
`;

const DragDropZone = styled.div`
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const EnvioArquivos = (props) => {
  const [arquivos, setArquivos] = useState([]);
  const [erro, setErro] = useState(null);

  const [dados, setDados] = useState([]);

  const [envio, setEnvio] = useState({
    boleto: [],
    boletoAluguel: [],
    cartas: [],
    contracheque: [],
    inadimplencia: [],
    extrato: [],
    contraponto: [],
  });

  /*
    -- MONTAGEM DOS DADOS
     */

  const montagemDados = () => {
    try {
      let boletos = envio["boleto"];
      let cartas = envio["cartas"];
      let extrato = envio["extrato"];
      let contracheque = envio["contracheque"];
      let contraponto = envio["contraponto"];
      let boletoAluguel = envio["boletoAluguel"];

      const agrupadoEntregador = groupBy(
        boletos,
        (boleto) => boleto.entregador
      );
      const entregadores = Object.keys(agrupadoEntregador);

      let agrupador = entregadores.reduce((acc, entregador) => {
        acc[entregador] = groupBy(
          agrupadoEntregador[entregador],
          (boleto) => boleto.codigoCondominio
        );
        return acc;
      }, {});

      const cartasAgrupado = groupBy(cartas, (c) => c.condominio);
      const extratoAgrupado = groupBy(extrato, (c) => c.condominio);

      const contrachequeImprime = contracheque.filter((c) => c.imprimePonto);
      const contrachequeNaoImprime = contracheque.filter(
        (c) => !c.imprimePonto
      );

      const contrapontoImprime = contraponto.filter((c) => c.imprimePonto);
      const contrapontoNaoImprime = contraponto.filter(
        (c) => !c.imprimePonto
      );

      let dt = entregadores.map((entregador) => {
        const itens = Object.keys(agrupador[entregador]).map((condominio) => {
          const grupo = agrupador[entregador][condominio];
          return {
            codigoCondominio: grupo[0].codigoCondominio,
            nomeCondominio: grupo[0].nomeCondominio,
            numeroRegistros: grupo.length,
          };
        });
        const total = Object.values(agrupador[entregador]).reduce(
          (acc, item) => acc + item.length,
          0
        );
        return { entregador, itens, total };
      });

      const boletosEmailAdicionais = boletos.filter(
        (c) => c.entregador !== "EM" && c.email
      );

      if (boletosEmailAdicionais.length > 0) {
        const itemBoletosAdicionais = {
          entregador: "EM-AD",
          itens: [
            {
              codigoCondominio: "00000",
              nomeCondominio: "EMAIL",
              numeroRegistros: boletosEmailAdicionais.length,
            },
          ],
          total: boletosEmailAdicionais.length,
        };

        dt.push(itemBoletosAdicionais);
      }

      const objCartas = [];
      Object.keys(cartasAgrupado).forEach((condominio) => {
        const carta = cartasAgrupado[condominio];
        const groupEntregador = groupBy(carta, (c) => c.entregador);
        const cartasCondo = Object.keys(groupEntregador).map((e) => {
          const carta = groupEntregador[e];
          return {
            codigoCondominio: condominio,
            entregador: e,
            numPaginas: carta[0].numPaginas,
            total: carta.length,
          };
        });
        objCartas.push(...cartasCondo);
      });

      const objExtrato = [];
      Object.keys(extratoAgrupado).forEach((condominio) => {
        const carta = extratoAgrupado[condominio];
        const groupEntregador = groupBy(carta, (c) => c.entregador);
        Object.keys(groupEntregador).forEach((entregador) => {
          const carta = groupEntregador[entregador];
          const groupTipo = groupBy(carta, (c) => c.tipo);
          const cartasCondo = Object.keys(groupTipo).map((e) => {
            const carta = groupTipo[e];
            return {
              codigoCondominio: condominio,
              entregador: entregador,
              total: carta.length,
              tipo: e,
            };
          });
          objExtrato.push(...cartasCondo);
        });
      });

      const entregadoresCartas = groupBy(objCartas, (c) => c.entregador);
      const entregador = new Set([
        ...Object.keys(entregadoresCartas),
        ...Object.keys(agrupadoEntregador),
      ]);

      let objContracheque = [];
      console.log("total");
      console.log(contracheque.length);
      if (contracheque.length) {
        objContracheque = [
          { imprimePonto: true, total: contrachequeImprime.length },
          { imprimePonto: false, total: contrachequeNaoImprime.length },
        ];
      }
      if (contraponto.length) {
        objContracheque = [
          { imprimePonto: true, total: contrapontoImprime.length },
          { imprimePonto: false, total: contrapontoNaoImprime.length },
        ];
      }

      let objInadimplencia = [];
      if (envio["inadimplencia"].length) {
        objInadimplencia = [...envio["inadimplencia"]];
      }

      setDados({
        boleto: dt,
        cartas: objCartas,
        entregador: [...entregador],
        contracheque: contracheque,
        contraponto: contraponto,
        inadimplencia: objInadimplencia,
        extrato: objExtrato,
        boletoAluguel: boletoAluguel,
        agrupador: agrupador,
        cartasAgrupado: cartasAgrupado,
        extratoAgrupado: extratoAgrupado,
        contrachequeImprime: contrachequeImprime,
        contrachequeNaoImprime: contrachequeNaoImprime,
        contrapontoImprime: contrapontoImprime,
        contrapontoNaoImprime: contrapontoNaoImprime,
        entregadores: entregadores,
      });
    } catch (error) {
      console.log(error);
    }
  };
  /*
    -- Fim da MONTAGEM DOS DAODS
     */

  const [openDialog, setOpenDialog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [tipoArquivo, setTipoArquivo] = useState([]);
  const [sucesso, setSucesso] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const arqs = [...arquivos];

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onloadstart = () => {
          setProcessing(true);
        };
        reader.onloadend = async (e) => {
          setErro(null);
          try {
            console.log('TIPO ------', e.target.result)
            
            const parser = new MultiParser(file, e.target.result);
            const { tipo, dados } = await parser.parse();
            console.log('TIPO ------', tipo)
            console.log('TIPO ------', dados)
            setEnvio((envio) => ({
              ...envio,
              [tipo]: [...envio[tipo], ...dados],
            }));
            setProcessing(false);
          } catch (erro) {
            console.log(erro);
            setErro(erro.message);
            const index = arqs.indexOf(file);
            if (index > -1) {
              arqs.splice(index, 1);
            }
            setProcessing(false);
          }
        };
        reader.readAsText(file);
        arqs.push(file);
      });

      setArquivos(arqs);
      // Do something with the files
    },
    [arquivos]
  );

  const submit = () => {
    setProcessing(true);
    montagemDados();
    setProcessing(false);
    setOpenDialog(true);
  };

  const finalizar = async (dados) => {
    try {
      if (arquivos.length === 0) {
        throw new Error("");
      }
      setProcessing(true);
      setOpenDialog(false);
      await Api.Envio.sendFiles(arquivos, dados, tipoArquivo);
      setEnvio({
        boleto: [],
        cartas: [],
        boletoAluguel: [],
        contracheque: [],
        contraponto: [],
        inadimplencia: [],
        extrato: [],
      });
      setSucesso(true);
      setArquivos([]);
      setTipoArquivo([]);
    } catch (erro) {
      console.log(erro);
      setOpenDialog(true);
    }
    setProcessing(false);
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const appendTipoArquivo = (tipo) => {
    if (!tipoArquivo.includes(tipo)) {
      setTipoArquivo([...tipoArquivo, tipo]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Wrapper>
      <Logo src={`${process.env.PUBLIC_URL}/logo.png`}></Logo>
      <Grid>
        <Grid.Column width={12}>
          <Form
            error={erro !== null}
            success={sucesso}
            loading={processing}
            onSubmit={submit}
          >
            <Message
              success
              content="Envio realizado com sucesso"
              icon="check circle"
            />
            <Message error content={erro} icon="times circle" />
            <DragDropZone {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <Header color="black">
                  <Header.Content>
                    <Icon name="cloud upload" size="huge" />
                    Solte os arquivos para fazer o envio
                  </Header.Content>
                </Header>
              ) : (
                <Header color="black">
                  <Header.Content>
                    <Icon name="add circle" size="huge" />
                    Arraste arquivos ou clique para selecionar
                  </Header.Content>
                </Header>
              )}
            </DragDropZone>
            <Table padded selectable color="black">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Nome do arquivo</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Tamanho</Table.HeaderCell>
                  <Table.HeaderCell>Tipo</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Remover</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {arquivos.map((arquivo) => {
                  return (
                    <Table.Row key={arquivo.name}>
                      <Table.Cell>{arquivo.name}</Table.Cell>
                      <Table.Cell>
                        {(arquivo.size / 1000).toFixed(0)}Kb
                      </Table.Cell>
                      <Table.Cell>
                        <TipoDocumento appendTipoArquivo={appendTipoArquivo}>
                          {arquivo.name}
                        </TipoDocumento>
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <Form.Button color="black" fluid size="massive">
              <Icon.Group>
                <Icon name="globe" />
                <Icon name="lock" inverted corner />
              </Icon.Group>
              &nbsp;Criptografar e enviar
            </Form.Button>
          </Form>
        </Grid.Column>
        <Grid.Column width={4}>
          <Resumo boletos={envio} />
        </Grid.Column>
      </Grid>
      <Grid textAlign="center">
        <Grid.Column width={12}>
          <Segment>
            <Header as="h4" content="Padrões de arquivos" dividing></Header>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Padrão</Table.HeaderCell>
                  <Table.HeaderCell>Variáveis</Table.HeaderCell>
                  <Table.HeaderCell>Tipo</Table.HeaderCell>
                  <Table.HeaderCell>Exemplo</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <strong>[EE]</strong>_REG_<strong>[AAAA][MM][T]</strong>.C
                    <strong>[SSS]</strong>
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      EE = Entregador 2 letras
                      <br />
                      AAAA = Ano 4 digitos
                      <br />
                      MM = Mês 2 digitos
                      <br />
                      T = Tipo 1 letra
                      <br />
                      SSS = Sequencial 3 digitos
                    </span>
                  </Table.Cell>
                  <Table.Cell>Boleto</Table.Cell>
                  <Table.Cell>PT_REG_202011N.C001</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    <strong>[EN]_[UUUUUU]_</strong>[LLLLL][AAAA][MM][T].L[SSS]
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      EN = Entregador 2 digitos
                      <br />
                      UUUUUU = Usuário N digitos
                      <br />
                      LLLLL = Livre N digitos
                      <br />
                      AAAA = Ano 4 digitos
                      <br />
                      MM = Mês 2 digitos
                      <br />
                      T = Tipo 1 letra
                      <br />
                      SSS = Sequencial 3 digitos
                    </span>
                  </Table.Cell>
                  <Table.Cell>Boleto - Aluguel</Table.Cell>
                  <Table.Cell>en_TESTE_202011N.L000</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    CIR_<strong>[CCCCC]</strong>_<strong>[NOME COND.]</strong>
                    _[ASSUNTO]_[AR|SIMPLES|REGISTRADO].zip
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      CCCCC = Código condomínio 5 digitos
                      <br />
                      NOME COND. = Nome do condomínio com espaços
                      <br />
                      ASSUNTO = Assunto 1 palavra
                      <br />
                      AR|SIMPLES|REGISTRADO = Tipo de postagem
                      <br />
                    </span>
                  </Table.Cell>
                  <Table.Cell>Cartas - Circular</Table.Cell>
                  <Table.Cell>
                    CIR_00001_CONDOMINIO TESTE_MANUTENCAO_SIMPLES.zip
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    ATA_[CCCCC]_[NOME
                    COND.]_[AGE|AGO|AGJ]_[DD][MM][AAAA]_[AR|SIMPLES|REGISTRADO]
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      CCCCC = Código condomínio 5 digitos
                      <br />
                      NOME COND. = Nome do condomínio com espaços
                      <br />
                      AGE|AGO|AGJ = Tipo
                      <br />
                      DD = Dia
                      <br />
                      MM = Mês
                      <br />
                      AAAA = Ano
                      <br />
                      AR|SIMPLES|REGISTRADO = Tipo de postagem
                      <br />
                    </span>
                  </Table.Cell>
                  <Table.Cell>Cartas - Ata</Table.Cell>
                  <Table.Cell>
                    ATA_00001_CONDOMINIO_TESTE_AGE_12112020_SIMPLES.zip
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    CON_[CCCCC]_[NOME
                    COND.]_[AGE|AGO|AGJ]_[DD][MM][AAAA]_[AR|SIMPLES|REGISTRADO]
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      CCCCC = Código condomínio 5 digitos
                      <br />
                      NOME COND. = Nome do condomínio com espaços
                      <br />
                      AGE|AGO|AGJ = Tipo
                      <br />
                      DD = Dia
                      <br />
                      MM = Mês
                      <br />
                      AAAA = Ano
                      <br />
                      AR|SIMPLES|REGISTRADO = Tipo de postagem
                      <br />
                    </span>
                  </Table.Cell>
                  <Table.Cell>Cartas - Convocação</Table.Cell>
                  <Table.Cell>
                    CON_00001_CONDOMINIO_TESTE_AGE_12112020_SIMPLES.zip
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>CONTRA_[CC]_[LL]</Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      CC = Sigla da Empresa em 2 dígitos
                      <br />
                      LL = Sequêncial em 2 dígitos
                      <br />
                    </span>
                  </Table.Cell>
                  <Table.Cell>Contracheque</Table.Cell>
                  <Table.Cell>CONTRA_CR_01.txt</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>PONTO_[CC]_[LL]</Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      CC = Sigla da Empresa em 2 dígitos
                      <br />
                      LL = Sequêncial em 2 dígitos
                      <br />
                    </span>
                  </Table.Cell>
                  <Table.Cell>Folha para Ponto</Table.Cell>
                  <Table.Cell>PONTO_CR_01.txt</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    EXPAAAAMM.ZIP
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ fontSize: 11 }}>
                      AAAA = Ano
                      <br />                      
                      MM = Mês                      
                      <br />
                    </span>
                  </Table.Cell>
                  <Table.Cell>Extrato - Proprietário</Table.Cell>
                  <Table.Cell>
                    EXP202107.zip
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </Grid.Column>
      </Grid>
      {/* <DialogFinalizacao open={openDialog} onClose={onClose} finalizar={finalizar} dados={envio} boletos={envio['boleto']} contracheque={envio['contracheque']} boletoAluguel={envio['boletoAluguel']} cartas={envio['cartas']} /> */}
      <DialogFinalizacao
        open={openDialog}
        onClose={onClose}
        finalizar={finalizar}
        dados={dados}
      />
    </Wrapper>
  );
};

export default EnvioArquivos;
