import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Menu,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import Api from "../../Api";
import Filtros from "../FiltrosProducao/FiltrosProducao";
import Historico from "../Historico/Historico";
import DialogVisualizacao from "./../Acompanhamento/DialogVisualizacao";
import EnvioPaginasExtrato from "./EnvioPaginasExtrato";
import "./estilos.css";
import LinhaProducao from "./LinhaProducao";

class Tabela extends React.Component {
  render() {
    const { dados, openVisualizacao, openHistorico, openExtrato } = this.props;
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Código</Table.HeaderCell>
            <Table.HeaderCell>Data do envio</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell
              className="hide-print"
              textAlign="right"
              width={1}
            >
              Download
            </Table.HeaderCell>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <Table.HeaderCell>Condomínios</Table.HeaderCell>

            <Table.HeaderCell>Total de itens</Table.HeaderCell>
            <Table.HeaderCell>Operador</Table.HeaderCell>
            <Table.HeaderCell>Entregadores</Table.HeaderCell>
            {/* <Table.HeaderCell
              className="hide-print"
              textAlign="right"
              width={1}
            >
              Download
            </Table.HeaderCell> */}
            <Table.HeaderCell
              className="hide-print"
              textAlign="right"
              width={1}
            >
              Resumo
            </Table.HeaderCell>
            <Table.HeaderCell
              className="hide-print"
              textAlign="right"
              width={1}
            >
              Histórico
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dados &&
            dados.docs.map((doc) => (
              <LinhaProducao
                doc={doc}
                openVisualizacao={openVisualizacao}
                openExtrato={openExtrato}
                openHistorico={openHistorico}
              />
            ))}
        </Table.Body>
      </Table>
    );
  }
}

const Producao = (props) => {
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [filtros, setFiltros] = useState({});

  const [resumo, setResumo] = useState(null);
  const [docHistorico, setDocHistorico] = useState(null);
  const ref = useRef(null);

  const [docExtrato, setDocExtrato] = useState(null);

  async function load(cancelToken, pagina, filtros) {
    setLoading(true);
    try {
      const dados = await Api.Envio.list(cancelToken, pagina, filtros);
      setDados(dados);
    } catch (erro) {
      setDados(null);
    }
    setLoading(false);
  }

  async function exportar() {
    try {
      setLoading(true);
      const cancelToken = Api.Envio.getCancelToken();
      await Api.Envio.export(cancelToken, filtros);
      setLoading(false);
    } catch (erro) {
      setLoading(false);
    }
  }

  useEffect(() => {
    const cancelToken = Api.Envio.getCancelToken();
    load(cancelToken, pagina, filtros);
    return () => cancelToken.cancel();
  }, [pagina, filtros]);

  const aplicarFiltros = (filtros) => {
    setFiltros(filtros);
  };

  const openVisualizacao = (resumo) => setResumo(resumo);
  const closeVisualizacao = () => setResumo(null);

  const openHistorico = (doc) => setDocHistorico(doc);
  const closeHistorico = () => setDocHistorico(null);

  const openEnvioExtrato = (doc) => setDocExtrato(doc);
  const closeEnvioExtrato = () => setDocExtrato(null);

  return (
    <>
      <Menu secondary attached="top" className="menu_principal">
        <Menu.Menu>
          <Menu.Item>
            <Header as="h2">Produção</Header>
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              content="Exportar"
              circular
              color="black"
              icon="download"
              on
              onClick={() => exportar()}
            />
          </Menu.Item>
          <Menu.Item>
            <ReactToPrint
              trigger={() => (
                <Button
                  content="Imprimir"
                  circular
                  floated="right"
                  color="black"
                  icon="print"
                />
              )}
              content={() => ref.current}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment attached padded>
        <Filtros applyFilters={aplicarFiltros} filtros={filtros} />
        <Tabela
          ref={ref}
          dados={dados}
          openHistorico={openHistorico}
          openVisualizacao={openVisualizacao}
          openExtrato={openEnvioExtrato}
        />
        <Pagination
          totalPages={(dados && dados.totalPages) || 1}
          defaultActivePage={1}
          onPageChange={(e, t) => setPagina(t.activePage)}
        />
        <DialogVisualizacao resumo={resumo} onClose={closeVisualizacao} />
        <Historico doc={docHistorico} onClose={closeHistorico} />
        <EnvioPaginasExtrato envio={docExtrato} close={closeEnvioExtrato} />
        <Dimmer active={loading} inverted>
          <Loader>Carregando</Loader>
        </Dimmer>
      </Segment>
    </>
  );
};

export default Producao;
