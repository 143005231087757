import api from './api'
import Axios from 'axios'

export default class Estatistica {
    
    static getCancelToken = () => Axios.CancelToken.source()

    static get_estatisticas = async (periodo, cancelToken) => {
        const req = await api.get(`/stats`, {
            params:{
                ...periodo
            },
            cancelToken: cancelToken.token
        });
        return req.data
    }

    static relatorio_producao = async (periodo, cancelToken) => {
        const req = await api.get(`/stats/relatorio-excel`, {
            params:{ ...periodo },
            cancelToken: cancelToken.token
        });
        return req.data
    }

    static relatorio = async (periodo, cancelToken) => {
        const req = await api.get(`/stats/relatorio`, {
            params:{ ...periodo },
            cancelToken: cancelToken.token
        });
        return req.data
    }

    static protocolo = async (periodo, entregador, condominios) => {
        const params = { ...periodo };
        if(entregador) {
            params.entregador = entregador;
        }
        params.condominios = condominios.map(c => c.codigo)
        const req = await api.get(`/pdf`, {
            params,
        });
        return req.data
    }
}