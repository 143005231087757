
const pattern = /\d{4}C(\d{5}).{3}(.{22})(\w{2})(.{142})(.{65})(.{5})(.{20})(.{40})(\w{2}).{16}(.{30})(.{122})(.{80})(.{50}).{7}(.{50})(.{45})/gi

const parts = {
    codigoCondominio: 1,
    unidade: 2,
    entregador: 3,
    nome: 4,
    endereco: 5,
    numero: 6,
    complemento: 7,
    cidade: 8,
    uf: 9,
    condominio: 11,
    email: 14,
    cepTelefone: 15
}

class Inadimplencia{
    file = null
    filename;
    constructor(filename, file) {
        this.file = file
        this.filename = filename
    }

    async parse(){
        
        const lines = this.file.toString('utf-8').split('\n');
        const lines1 = lines.filter(l => l.match(/^\d{4}/gi))

        const dados = lines1.map(linha => {
            if(linha){
                const sections = linha.split(pattern);
                if(sections && sections.length){
                    const obj = Object.keys(parts).reduce((acc, chave) => {
                        return {...acc, [chave]: sections[parts[chave]] && sections[parts[chave]].trim()}
                    }, {})
                    
                    let tipoEnvio = [];
                    if(obj.cepTelefone) {
                        if(obj.cepTelefone.length > 8){
                            tipoEnvio.push('SMS')
                        }

                        if(obj.email) {
                            tipoEnvio.push('EMAIL')
                        }
                    }

                    if(tipoEnvio.length === 0){
                        tipoEnvio.push('CARTA')
                    }

                    obj.tipoEnvio = tipoEnvio.join('+')
                    return obj
                }
            }
            return null
        })
        
        
        return [{arquivo: this.filename, total: dados.filter(d => d).length, tipo: 'inadimplência'}]
    }
}

export default Inadimplencia