import React from 'react'
import { Modal, Header, List } from 'semantic-ui-react';
import { isArray } from 'util';
import ItemHistorico from './ItemHistorico';

const Historico = props => {
    const { doc, onClose } = props;

    if (!doc) {
        return <></>
    }

    return (
        <Modal open={doc !== null} size="small" onClose={onClose} closeOnEscape closeIcon>
            <Modal.Header>
                <Header as="h2">Histórico da impressão {doc.codigo}</Header>
            </Modal.Header>
            <Modal.Content>
                <List animated divided>
                    {isArray(doc.historico) && doc.historico.sort((a,b) => new Date(a.data).getTime() > new Date(b.data).getTime() ? -1 : 1 ).map((historico, i) => <ItemHistorico key={i}>{historico}</ItemHistorico>)}
                </List>
            </Modal.Content>
        </Modal>
    )
}

export default Historico