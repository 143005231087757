import React from 'react'
import { Header, Modal, Table } from 'semantic-ui-react';

const DialogDestinatariosEmails = props => {
    const { doc, onClose } = props;

    if(!doc) {
        return <></>
    }

    return <Modal open={doc} closeIcon={true} onClose={onClose}>
        <Modal.Header>
            <Header as="h3">Destinatários da campanha</Header>
        </Modal.Header>
        <Modal.Content>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {Array.isArray(doc?.resumo?.emails) && doc.resumo.emails.map(item => {
                        return <Table.Row key={item.email}>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell>{item.email}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
        </Modal.Content>
    </Modal>
}

export default DialogDestinatariosEmails;