import React, { useEffect } from 'react'
import { Table } from 'semantic-ui-react';

const Resumo = props => {
    const { boletos } = props;

    useEffect(() => {
        
    }, [])
    
    //const agrupador = groupBy(boletos, boleto => boleto.codigoCondominio)
    //const condominios = Object.keys(agrupador);

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Tipo</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Qtd.</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell><strong>Boletos</strong></Table.Cell>
                    <Table.Cell><strong>{boletos['boleto'].length}</strong></Table.Cell>
                </Table.Row>                
                <Table.Row>
                    <Table.Cell><strong>Cartas</strong></Table.Cell>
                    <Table.Cell><strong>{boletos['cartas'].length}</strong></Table.Cell>
                </Table.Row>                
            </Table.Body>
        </Table>
    )
}

export default Resumo
