import React, { useEffect, useState } from 'react';
import { Dimmer, Header, Loader, Pagination, Segment, Table } from 'semantic-ui-react';
import { isArray } from 'util';
import Api from '../../Api';
import Filtros from '../Filtros/Filtros';
import Historico from '../Historico/Historico';
import CancelamentoParcial from './CancelamentoParcial';
import DialogVisualizacao from './DialogVisualizacao';
import LinhaAcompanhamento from './LinhaAcompanhamento';

const Acompanhamento = props => {
    const [dados, setDados] = useState(null);
    const [loading, setLoading] = useState(false)
    const [pagina, setPagina] = useState(1)
    
    const [resumo, setResumo] = useState(null);
    const [filtros, setFiltros] = useState({});
    const [historico, setHistorico] = useState(null);
    const [cancelamento, setCancelamento] = useState(null);

    const openHistorico = (doc) => setHistorico(doc)
    const closeHistorico = () => setHistorico(null)

    const openCancelamento = (doc) => setCancelamento(doc)

    const closeCancelamento = (dt = null) => {
        setCancelamento(null)
        if(dt) {
           const index = isArray(dados.docs) && dados.docs.findIndex(c => c.codigo === dt.codigo)
           const arr = [...dados.docs];
           arr[index] = dt;
           setDados({...dados, docs: arr})
        }
    }

    async function load(cancelToken, pagina, filtros){
        setLoading(true)
        try{
            const dados = await Api.Envio.list(cancelToken, pagina, filtros)
            setDados(dados);
        } catch(erro){
            setDados(null);
        }
        setLoading(false);  
    }

    useEffect(() => {
        const cancelToken = Api.Envio.getCancelToken()
        load(cancelToken, pagina, filtros);
        return () => cancelToken.cancel();
    }, [pagina, filtros])

    const openVisualizacao = (resumo) => setResumo(resumo)
    const closeVisualizacao = () => setResumo(null)
    
    return (
        <>
            <Header as="h2" attached="top">
                <Header.Content>
                    Acompanhamento
                </Header.Content>
            </Header>
            <Filtros applyFilters={(filtros) => setFiltros(filtros)} filtros={filtros} acompanhamento/>
            <Segment  attached padded>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Código
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Data do envio
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Tipo
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Total de itens
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Entregadores
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Condomínios
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" width={2}>
                                Visualizar Resumo
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" width={2}>
                                Histórico
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            dados && dados.docs.map(doc => {
                                return (
                                    <LinhaAcompanhamento key={doc._id} doc={doc} openCancelamento={openCancelamento} openHistorico={openHistorico} openVisualizacao={openVisualizacao} />
                                )
                            })
                        }
                    </Table.Body>
                </Table>
                <Pagination totalPages={(dados && dados.totalPages) || 1} defaultActivePage={1} onPageChange={(e,t) => setPagina(t.activePage)} />
                <DialogVisualizacao resumo={resumo} onClose={closeVisualizacao} />
                <CancelamentoParcial registro={cancelamento} closeCancelamento={closeCancelamento} />
                <Historico doc={historico} onClose={closeHistorico} />
                <Dimmer active={loading} inverted>
                    <Loader>Carregando</Loader>
                </Dimmer>
            </Segment>
        </>
    )
}

export default Acompanhamento
