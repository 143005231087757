import csvtojsonV2 from "csvtojson/v2";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { isArray } from "util";
import ButtonLink from "../../Helpers/ButtonLink/ButtonLink";
import "./style.css";
const CsvViewerInadimplencia = (props) => {
  const {
    data,
    hasLink,
    linkKey,
    linkDestFn,
    formatDate,
    dateFormat,
    dateFields,
    showTotal,
  } = props;
  const [keys, setKeys] = useState([]);
  const [dados, setDados] = useState([]);

  const readData = async (data) => {
    console.log(isArray(dateFields));
    const regs = await csvtojsonV2({
      delimiter: [";", ","],
    }).fromString(data);
    if (isArray(regs)) {
      setKeys(Object.keys(regs[0]));
      setDados(regs);
    }
  };

  useEffect(() => {
    readData(data);
    return () => {
      setDados([]);
      setKeys([]);
    };
  }, [data]);

  return (
    <>
      {showTotal && (
        <label>Total de registros: {dados ? dados.length : 0}</label>
      )}
      <Table>
        <Table.Header>
          <Table.Row>
            {keys.map((key) => (
              <Table.HeaderCell key={key}>
                {key.includes("_")
                  ? key.replace("_", ". ").replace("_", " ")
                  : key}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dados.map((dado, i) => {
            return (
              <Table.Row key={i}>
                {keys.map((k, j) => (
                  <Table.Cell key={`${k}-${i}${j}`}>
                    {(hasLink && k === linkKey && (
                      <ButtonLink
                        content={
                          formatDate
                            ? isArray(dateFields)
                              ? dateFields.includes(k)
                                ? moment(dado[k])
                                    .add(3, "hour")
                                    .format(dateFormat)
                                : dado[k]
                              : dado[k]
                            : dado[k]
                        }
                        onClick={() => linkDestFn(dado)}
                      />
                    )) ||
                      (formatDate && dateFields.includes(k)
                        ? moment(dado[k]).add(3, "hour").format(dateFormat)
                        : dado[k] || dado[k])}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default CsvViewerInadimplencia;
