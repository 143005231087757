import React, {useState, useEffect} from 'react'
import Api from '../../Api'
import { Header, Segment, Divider } from 'semantic-ui-react'
import moment from 'moment'
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

const Estatisticas = props => {
    moment.locale('pt-BR');
    const [periodo, setPeriodo] = useState({
        dataInicio: '',
        dataFim: '',
    })
    const [foco, setFoco] = useState(null);

    const [dados, setDados] = useState(null)

    const fetch = async (periodo, cancelToken) => {
        try {
            const dados = await Api.Estatistica.get_estatisticas(periodo, cancelToken)
            setDados(dados)
        } catch (erro){
            setDados(null)
        }
    }

    useEffect(() => {
        const cancel = Api.Estatistica.getCancelToken();
        if(periodo.dataInicio && periodo.dataFim) {
            fetch(periodo, cancel)        
        }
        return () => cancel.cancel()
    }, [periodo])

    return (
        <>
            <Header as="h2" attached="top" content="Estatísticas" dividing />
            <Segment attached="bottom">
            <Header as="h4" content="Periodo"/>
            <DateRangePicker
                startDate={periodo.dataInicio} // momentPropTypes.momentObj or null,
                numberOfMonths={2}
                minimumNights={0}
                enableOutsideDays={true}
                isOutsideRange={() => false}
                endDate={periodo.dataFim} // momentPropTypes.momentObj or null,
                onDatesChange={({ startDate, endDate }) => setPeriodo({ dataInicio: startDate, dataFim: endDate})} // PropTypes.func.isRequired,
                focusedInput={foco} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFoco(focusedInput || 'startDate')} // PropTypes.func.isRequired,
                startDatePlaceholderText="Início"
                endDatePlaceholderText="Fim"
                onClose={() => setFoco(null)}
            />
            { dados !== null &&
                <>
                    <Header content="Resumo" as="h2" dividing />            
                    <Header as="h3">
                        <span style={{float: 'right'}}>{dados.boleto.total}</span>
                        <Header.Content>Boleto</Header.Content>
                    </Header>
                    <Header as="h3">
                        <span style={{float: 'right'}}>{dados.cartas.total}</span>
                        <Header.Content>Cartas</Header.Content>
                    </Header>
                    <Header as="h3">
                        <span style={{float: 'right'}}>{dados.cartas.paginas}</span>
                        <Header.Content>Cartas: Total de páginas</Header.Content>
                    </Header>
                </>
            }
            <Divider />
            </Segment>
        </>
    )

}

export default Estatisticas