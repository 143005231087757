
// const padrao = { pattern: /^7(.{103})(.{12})(.{12})(.{40})(.{7})(.{3})(.{7})(.{3})(.{7})(.{3})(.{7})(.{3})(.{3})(.{8})(S|N)/gi, partes: {
//     funcao: 2,
//     imprimePonto: 15
// }}


const padrao = { pattern: /^01(\d{4})(.{3})(.{50})(.{43})(.{20})(.{8})(.{1})(\d{5})/gi, partes: {
    codCondominio:1,
    nomeCondominio:3,
    nomePessoa:4,
    profissao:5,
    valor:6,
    codPessoa:7
}}


const padrao7 = { pattern: /^07(.{2})(\w{1})/gi, partes: {
    imprimePonto:2,    
}}

const filenamePattern = {
    pattern: /^CONTRA_(\w{2})_(.{2})/gi, tipo: 'Contracheque', partes: {
    Entregador: 1,    
    Sequencia: 2
}}


class Contracheque{

    file = null
    entregador = null;
    constructor(filename, file) {
        this.file = file
        this.filename = filename
        this.entregador = filename.split(filenamePattern.pattern)[filenamePattern.partes.Entregador]
    }

    async parse(){
        const lines = this.file.split('\n');
        const lines1 = lines.filter(l => l.startsWith('01'));        
        const lines7 = lines.filter(l => l.startsWith('07'));
        const data = lines1.map((linha,i) => {
            console.log(linha);
            const sections = linha.split(padrao.pattern);

            // const line7 = lines7[i].split(padrao7.pattern);
            const line7 = lines7[i] && lines7[i].split(padrao7.pattern) || {imprimePonto: null};

            if(sections && sections.length){
                const obj = Object.keys(padrao.partes).reduce((acc, chave) => {

                    return {...acc, [chave]: sections[padrao.partes[chave]] && sections[padrao.partes[chave]].trim(),imprimePonto:((!line7.imprimePonto || obj.imprimePonto === 'N' )?false:true)}
                }, {})              
                
                return { ...obj };
            }
            return null
        })
        return data;
    }
}

export default Contracheque