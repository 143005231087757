import React, { useState } from 'react'
import { Segment, Header } from 'semantic-ui-react'
import ListaUsuarios from './ListaUsuarios'
import FormUsuario from './FormUsuario'

const Usuarios = props => {
    const [refresh, setRefresh] = useState(1);

    return (
        <>
            <Header as="h2" attached="top">
                <Header.Content>
                    Cadastro de usuário
                </Header.Content>
            </Header>
            <Segment  attached padded>
                <FormUsuario refresh={() => setRefresh(refresh+1)} />
            </Segment>

            <Header as="h2" attached="top">
                <Header.Content>
                    Usuários
                </Header.Content>
            </Header>
            <Segment attached padded>
                <ListaUsuarios refresh={() => setRefresh(refresh+1)} shouldRefresh={refresh}/>
            </Segment>
        </>
    )
}
  
export default Usuarios