import html2canvas from 'html2canvas'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { useHistory, useParams } from 'react-router-dom'
import { Button, ButtonGroup, Dimmer, Input, Loader } from 'semantic-ui-react'
import Api from '../../Api'

const CriadorEmail = props => {
    const history = useHistory()
    const { id } = useParams();

    const editor = useRef(null)
    
    const [templateId, setTemplateId] = useState(null)
    const [templateName, setTemplateName] = useState('Template sem nome')

    const [loading, setLoading] = useState(false);
    
    const changeTemplateName = useCallback(event => {
        setTemplateName(event.target.value)
    }, [])

    const loadTemplate = useCallback( async (id) => {
        try {
            const data = await Api.CampanhaEmail.getCampanhaPorId(id);
            setTemplateName(data.name);
            editor.current.loadDesign(data.template_json);    
        } catch(error) {
            alert("Erro ao carregar o template")
        }
    }, [])

    useEffect(() => {
        if(id) {
            setTemplateId(id);
            loadTemplate(id)
        }
    }, [id, loadTemplate])
    
    const exportHtml = useCallback(() => {
        editor.current.exportHtml(async data => {
            setLoading(true)
            const { design, html } = data
            document.getElementById('previa').innerHTML = html;
            const canvas = await html2canvas(document.getElementById('previa'));
            const obj = {
                name: templateName,
                template_json: design,
                template_compilado: html,
                previa_template: canvas.toDataURL("image/png")
            }
            document.getElementById('previa').innerHTML = '';
            try {
                const resp = await Api.CampanhaEmail.createOrUpdateCampanha(templateId, obj);
                if(resp) {
                    setTemplateId(resp._id);
                }
                alert("Template salvo com sucesso!")
                history.goBack();
            } catch(error) {
                alert("Erro ao salvar o template: "+error.message)
            } finally {
                setLoading(false);
            }
        })
    }, [editor, templateName, templateId, history])


    return <>
        <Dimmer active={loading} inverted>
            <Loader>Salvando template</Loader>
        </Dimmer>
        <div id="previa" style={{position: 'absolute', width: '500px', zIndex: -1}}></div>
        <div style={{display: 'flex', flexDirection: 'column', height:'100vh'}}>
            <div style={{height: 70, background: '#FAFAFA', borderBottom: '2px solid #000', display: 'flex', padding: 10}}>
                <Button basic color="gray" onClick={() => history.goBack()} title="VOLTAR"  icon="arrow left" />
                <div style={{flex: 1, alignItems: 'center', display: 'flex', flexFlow: 'row'}}>
                    <Input value={templateName} icon="edit" iconPosition="left" onChange={changeTemplateName} style={{fontSize: 18, fontWeight: 'bold'}} />
                </div>
                <ButtonGroup basic>
                    <Button color="black" icon="save" onClick={exportHtml} style={{width: 200}} fluid content="SALVAR MODELO"></Button>
                </ButtonGroup>
            </div>
            <EmailEditor ref={editor} options={{locale: 'pt-BR'}}></EmailEditor>
        </div>
    </>
}

export default CriadorEmail