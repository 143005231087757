import React, { useState, useEffect, useCallback } from 'react'
import { Form } from 'semantic-ui-react'
import Api from '../../Api'
import { isFunction } from 'util'

const AutoCompleteCondominio = props => {
    const { value, onChange, ...others } = props
    const [condominios, setCondominios] = useState([])
    const [condominiosOptions, setCondominiosOptions] = useState([])
    const [query, setQuery] = useState('')
    const [loading, setLoading] = useState(false);

    const load = useCallback(async (cancel, query) => {
        setLoading(true)
        const condominios = await Api.Prazo.getCondominios(cancel, query, 20)
        if(props.multiple){
            setCondominios([...value,...condominios]);
        } else {
            setCondominios(condominios);
        }
        setLoading(false)
    }, [props.multiple, value])

    const changeFieldValue = (e, t) => {
        let condominio;
        if(props.multiple){
            condominio = condominios.filter(c => t.value.includes(c.codigo))
        } else {
            condominio = condominios.find(c => c.codigo === t.value)
        }
        if(condominio) {
            isFunction(onChange) && onChange(e, {...t, value: condominio})
        } else {
            isFunction(onChange) && onChange(e, {...t, value: null})
        }
    }

    useEffect(() => {
        if(props.multiple){
            const map = [...value,...condominios].map(c => {
                return { key: c._id, value: c.codigo, text: `${c.codigo} - ${c.nome}` }
            })
            setCondominiosOptions(map)
        } else {
            const map = condominios.map(c => {
                return { key: c._id, value: c.codigo, text: `${c.codigo} - ${c.nome}` }
            })
            setCondominiosOptions(map)
        }
    }, [condominios, value, props.multiple])

    useEffect(() => {
        const cancel = Api.Prazo.getCancelToken()
        load(cancel, query)
        return () => cancel.cancel()
    }, [query, load])
    

    return <Form.Select multiple={props.multiple} loading={loading} value={!props.multiple ? value && value.codigo : value.map(c => c.codigo)} options={condominiosOptions} search onChange={changeFieldValue} onSearchChange={(e,t) => setQuery(t.searchQuery)} {...others}>

    </Form.Select>
}

export default AutoCompleteCondominio;