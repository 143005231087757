import { mapArrayToParts } from "../Helpers/functions"

const regexLines = {
    '0005': {
        pattern: /^0005.{12}(.{50})/gim,
        partes: {
            imobiliaria: 1
        }
    },
    '02': {
        pattern: /^02(.{5})(.{3})(.{25})(.{40})(.{100})(.{10})(.{8})(.{7})(.{40})(.{1})(.{14})(.{7})(.{40})(.{8})(.{8})(\w{1})(.{6})((.{10})(.{10})(.{10})(.{10}))?$/gim,
        partes: {
            codigoCondominio: 1,
            codigoBloco: 2,
            codigoEconomia: 3,
            nomeCondominio: 4,
            endereco: 5,
            tipoImovel: 6,
            codImovel: 7,
            codPessoa: 8,
            nomePessoa: 9,
            tipoPessoaProp: 10,
            cpfCnpjProp: 11,
            codProp: 12,
            nomeProp: 13,
            dataVencFianca: 14,
            dataReajAluguel: 15,
            tipoDoc: 16,
            competencia: 17,
            periodoRefIni: 18,
            periodoRefFim: 19,
            vigIncendioIni: 20,
            vigIncendioFim: 21,
        }
    },
    '0706': {
        pattern: /^0706(.{110})/gim,
        partes: {
            email: 1
        }
    }
}

const filenamePattern = {
    pattern: /(\w{2})_REG_(\d{4})(\d{2})(\w).C(\d{3})/gi, tipo: 'Boleto', partes: {
    'Entregador': 1,
    'Ano': 2,
    'Mês': 3,
    'Tipo':4,
    'Sequencia': 5
}}

class Boleto{
    file = null
    constructor(filename, file) {
        this.file = file
        this.filename = filename

        if(!filename.match(filenamePattern.pattern)){
            throw Error("Nome do arquivo não se refere a um boleto")
        }

        this.entregador = filename.split(filenamePattern.pattern)[filenamePattern.partes.Entregador]
    }

    async parse(){
        const header = this.file.split('\n')[0];

        let objBaseImobiliaria = {}
        const { partes: partesImob, pattern: patternImob } = regexLines['0005'];

        const grpsImob = header.split(patternImob)
        objBaseImobiliaria = mapArrayToParts(partesImob, grpsImob);
        
        const linesSplit = this.file.split(/^(?=02)/gim)
        const [ , ...regs] = linesSplit;

        const arr = regs.map(reg => {
            const lines = reg.split('\n')

            const lines2 = lines.filter(l => l.startsWith('02'))
            const lines0706 = lines.filter(l => l.startsWith('0706'))

            if(!lines2.length){
                return null;
            }
            const line = lines2[0]
            const { partes, pattern } = regexLines['02']
            const grps = line.split(pattern)
            
            let obj = mapArrayToParts(partes, grps);

            obj = {...objBaseImobiliaria, ...obj};

            obj.meio_entrega = 'CARTA';
            if(lines0706.length > 0) {
                const { partes:partes0706, pattern: pattern0706 } = regexLines['0706']
                const grps0706 = lines0706[0].split(pattern0706);
                const emailObj = mapArrayToParts(partes0706, grps0706);
                obj = {...obj, ...emailObj};
                obj.meio_entrega = 'EMAIL'
            }

            obj.entregador = this.entregador;
            return obj
        })

        return arr.filter(a => a)
    }

    _parseLine(tipo, data){

    }
}

export default Boleto