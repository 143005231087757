import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { Button, List } from 'semantic-ui-react'
import { MAIN_COLOR } from '../../environment'
import styles from './ShutterSelector.module.css'

const ShutterSelector = forwardRef((props, ref) => {
    const { renderItem, keyExtractor } = props;
    const [registrosInternal, setRegistrosInternal] = useState([])
    const [registrosSelecionados, setRegistrosSelecionados] = useState([]);

    useImperativeHandle(ref, () => ({
            setRegistros(registros) {
                setRegistrosInternal(registros);
            },

            limparSelecao() {
                setRegistrosSelecionados([]);
            },

            adicionarTodos() {
                setRegistrosSelecionados([...registrosInternal]);
            },

            getSelecionados() {
                return registrosSelecionados;
            }

        }),
        [registrosSelecionados, registrosInternal]
    )

    const selecionadosComKeys = useMemo(() => {
        return registrosSelecionados.map(item => ({
            item,
            key: keyExtractor(item)
        }))
    }, [registrosSelecionados, keyExtractor])
    
    const disponiveisComKeys = useMemo(() => {
        return registrosInternal.map(item => ({
            item,
            key: keyExtractor(item)
        }))
    }, [registrosInternal, keyExtractor])

    const disponiveis = useMemo(() => {
        const keysSelecionadas = selecionadosComKeys.map(item => item.key);
        
        return disponiveisComKeys.filter(item => !keysSelecionadas.find(c => c === item.key))
    }, [selecionadosComKeys, disponiveisComKeys])

    const addItem = useCallback((item) => {
        console.log(item);
        setRegistrosSelecionados(selecionados => [...selecionados, item])
    }, [])

    const removeItem = useCallback((item) => {
        const keyItem = keyExtractor(item);
        console.log(keyItem)
        setRegistrosSelecionados(selecionados => selecionados.filter(c => keyExtractor(c) !== keyItem))
    }, [keyExtractor])

    return <div className={styles.grid}>
        <div className={styles.gridList}>
            <List className={styles.list}>
                {disponiveis.map(item => <List.Item onClick={() => addItem(item.item)} key={item.key} content={renderItem(item.item)}></List.Item>)}
            </List>
        </div>
        <div className={styles.gridActions}>
            <Button icon="chevron right" onClick={() => setRegistrosSelecionados([...registrosInternal])} color={MAIN_COLOR} basic></Button>
            <Button icon="chevron left" onClick={() => setRegistrosSelecionados([])} color={MAIN_COLOR} basic></Button>
        </div>
        <div className={styles.gridList}>
            <List className={styles.list}>
                {selecionadosComKeys.map(item => <List.Item onClick={() => removeItem(item.item)} key={item.key} content={renderItem(item.item)}></List.Item>)}
            </List>
        </div>
    </div>
});

export default ShutterSelector