import React, { useState } from 'react'
import { Header, Segment, Divider, Form } from 'semantic-ui-react'
import Api from '../../Api'
import { DateRangePicker } from "react-dates";
import moment from 'moment'
import AutoCompleteCondominio from '../Prazos/AutoCompleteCondominio';


const optionsEntregador = [
    { key: 0, value: '', text: 'Todos' },
    { key: 1, value: 'PT', text: 'PT' },
    { key: 2, value: 'LC', text: 'LC' },
]

const Protocolo = props => {
    moment.locale('pt-BR');
    const [periodo, setPeriodo] = useState({
        dataInicio: '',
        dataFim: '',
    })
    const [foco, setFoco] = useState(null);
    const [condominios, setCondominios] = useState([]);
    const [entregador, setEntregador] = useState('PT');
    const [url, setUrl] = useState(null);

    const load = async () => {
        try{
            const p = { dataInicio: moment(periodo.dataInicio).format('YYYY-MM-DD'), dataFim: moment(periodo.dataFim).format('YYYY-MM-DD') }
            const data = await Api.Estatistica.protocolo(p, entregador, condominios);
            window.URL = window.webkitURL || window.URL;

            const dados = Uint8Array.from(atob(data), c => c.charCodeAt(0))
            const blob = new Blob([dados], {
                type: "application/pdf"
            });
            setUrl(window.URL.createObjectURL(blob));
        } catch(erro){
            setUrl('')
            console.log(erro)
        }
    }

    const download = url => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "procoloco.pdf"); 
        link.dataset.downloadurl = ['application/pdc', link.download, link.href].join(':');
        document.body.appendChild(link);
        link.click();
        link.remove()
    }

    return (
        <>
            <Header as="h2" attached="top" content="Protocolo" dividing />
            <Segment attached="bottom">
                <Form onSubmit={load}>
                        <Form.Field width={4}>
                            <label>Periodo</label>
                            <DateRangePicker
                                startDate={periodo.dataInicio} // momentPropTypes.momentObj or null,
                                numberOfMonths={2}
                                minimumNights={0}
                                enableOutsideDays={true}
                                noBorder
                                isOutsideRange={() => false}
                                endDate={periodo.dataFim} // momentPropTypes.momentObj or null,
                                onDatesChange={({ startDate, endDate }) => setPeriodo({ dataInicio: startDate, dataFim: endDate})} // PropTypes.func.isRequired,
                                focusedInput={foco} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput => setFoco(focusedInput || 'startDate')} // PropTypes.func.isRequired,
                                startDatePlaceholderText="Início"
                                endDatePlaceholderText="Fim"
                                onClose={() => setFoco(null)}
                            />
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Condomínio</label>
                            <AutoCompleteCondominio multiple name="condominio" required value={condominios} onChange={(e,{name, value}) => setCondominios(value)} />
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Entregador</label>
                            <Form.Select options={optionsEntregador} value={entregador} onChange={(e,{value}) => setEntregador(value)} />
                        </Form.Field>
                        <Form.Group>
                            <Form.Field>
                                <label style={{opacity: 0}}>Baixar</label>
                                <Form.Button size="large" color="black" basic content="Buscar dados" icon="search" />
                            </Form.Field>
                            { url &&
                                <Form.Field>
                                    <label style={{opacity: 0}}>Baixar</label>
                                    <Form.Button type="button" size="large" onClick={() => download(url)} color="black" content="Baixar" icon="download" />
                                </Form.Field>
                            }
                        </Form.Group>
                </Form>
                <Divider />
                { url &&
                    <iframe src={url} title="Protocolo" width="100%" height="800">
                    </iframe>
                }
                   
            </Segment>
        </>
    )

}

export default Protocolo