import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Button, Label, Select, Table } from "semantic-ui-react";
import Api from "../../Api";

const STATUS_OPTIONS = [
  { key: 1, value: "Recebido", text: "Recebido" },
  { key: 2, value: "Cancelado", text: "Cancelado" },
  { key: 2, value: "Produção", text: "Produção" },
  { key: 3, value: "Expedição", text: "Expedição" },
  { key: 4, value: "Expedido", text: "Expedido" },
  { key: 5, value: "Coletado", text: "Coletado" },
];

const colors = {
  Recebido: "black",
  Cancelado: "red",
  Expedido: "green",
  Produção: "blue",
  Expedição: "yellow",
  Coletado: "lightgrey",
};
const colorStatus = (status) => {
  return colors[status] || "black";
};

//const colorStatus = (status) => status === 'Cancelado' ? 'red' : ( status === 'Expedido' ? 'green' : 'black' )

const LinhaAcompanhamento = (props) => {
  const [editando, setEditando] = useState(false);
  const [status, setStatus] = useState(null);
  const { doc, openVisualizacao, openHistorico, openCancelamento } = props;

  const uniquevalues = (cartas) => {
    const condominios = cartas.map((carta) => carta.codigoCondominio);

    const unique = [...new Set(condominios)];

    return Array.isArray(unique) ? unique : [];
  };

  useEffect(() => {
    setEditando(false);
  }, [doc.codigo]);

  useEffect(() => {
    setStatus(doc.status);
    return () => setStatus(null);
  }, [doc.status]);

  const atualizaStatus = async () => {
    await Api.Envio.atualizaStatus(doc.codigo, status);
    doc.status = status;
    setEditando(false);
  };

  const totalBoleto =
    Array.isArray(doc?.resumo?.boleto) &&
    doc.resumo["boleto"].reduce((acc, atual) => acc + atual.total, 0) || 0;

  const totalCartas =
    Array.isArray(doc?.resumo?.cartas) &&
    doc.resumo["cartas"].reduce((acc, atual) => acc + atual.total, 0)|| 0;

  // const totalContracheque =
  //   Array.isArray(doc?.resumo?.contracheque) &&
  //   doc.resumo["contracheque"].reduce((acc, atual) => acc + atual.total, 0)|| 0;

  const totalContracheque = doc?.resumo?.contracheque?.length || 0; 

  // const totalContraponto =
  //   Array.isArray(doc?.resumo?.contraponto) &&
  //   doc.resumo["contraponto"].reduce((acc, atual) => acc + atual.total, 0)|| 0;

  const totalContraponto = doc?.resumo?.contraponto?.length || 0;

  const totalInadimplencia =
    Array.isArray(doc?.resumo?.inadimplencia) &&
    doc.resumo["inadimplencia"].reduce((acc, atual) => acc + atual.total, 0)|| 0;

  const totalExtratos =
    Array.isArray(doc?.resumo?.extrato) &&
    doc.resumo["extrato"].reduce((acc, atual) => acc + atual.total, 0)|| 0;

  const totalBoletosAluguel =
    Array.isArray(doc?.resumo?.boletoAluguel) &&
    doc.resumo["boletoAluguel"].length || 0;

  const totalEmails =
    Array.isArray(doc?.resumo?.emails) && doc.resumo["emails"].length || 0;
    
  return (
    <Table.Row key={doc._id}>
      <Table.Cell>{doc.codigo}</Table.Cell>
      <Table.Cell>
        <Moment format="DD/MM/YYYY HH:mm" add={{ hours: -1 }}>
          {doc.created_at}
        </Moment>
      </Table.Cell>
      <Table.Cell>
        {editando && (
          <>
            <Select
              onChange={(e, { value }) => setStatus(value)}
              value={status}
              options={STATUS_OPTIONS}
            />
            <Button
              icon="save"
              color="black"
              size="small"
              content="Salvar"
              onClick={atualizaStatus}
            />
          </>
        )}
        {!editando && (
          <>
            <Label color={colorStatus(doc.status)}>{doc.status}</Label>
            {doc.status === "Recebido" && (
              <Button
                icon="edit"
                compact
                color="black"
                basic
                onClick={() => setEditando(true)}
              />
            )}

            {doc.status === "Recebido" && doc.tipo.includes("Boleto") && (
              <Button
                size="tiny"
                color="red"
                icon="building outline"
                title="Cancelar condomínio"
                onClick={() => openCancelamento(doc)}
              ></Button>
            )}
          </>
        )}
        {doc.cancelamento_parcial && (
          <Label
            title="Houve cancelamento parcial"
            content="*"
            color="red"
            circular
          />
        )}
      </Table.Cell>
      <Table.Cell>
        {doc.tipo.map((tipo) => (
          <Label key={tipo} content={tipo} />
        ))}
      </Table.Cell>
      <Table.Cell>
        {totalBoleto +
          totalCartas +
          totalInadimplencia +
          totalContracheque +
          totalContraponto +
          totalExtratos +
          totalBoletosAluguel +
          totalEmails}
      </Table.Cell>
      <Table.Cell>
        {Array.isArray(doc?.resumo?.entregador) &&
          doc.resumo.entregador.map((entregador) => (
            <Label key={entregador} content={entregador} />
          ))}

        {!Array.isArray(doc?.resumo?.entregador) && doc?.resumo?.entregador}

        {doc.inclui_entrega_manual && <Label content="Manual" color="red" />}
      </Table.Cell>
      <Table.Cell>
        {Array.isArray(doc?.resumo?.cartas) &&
          uniquevalues(doc.resumo.cartas).map((codigo) => (
            <Label key={codigo} content={codigo} />
          ))}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          onClick={() => openVisualizacao(doc)}
          color="black"
          basic
          icon="search"
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          onClick={() => openHistorico(doc)}
          color="black"
          basic
          icon="history"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default LinhaAcompanhamento;
