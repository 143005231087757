import api from './api'
import Axios from 'axios'
import store from './../Store'
import ActionTypes from '../Store/ActionTypes'
import jwt_decode from 'jwt-decode'

export default class Auth {
    
    static getCancelToken = () => Axios.CancelToken.source()

    static login = async (username, password) => {
        const request = { username, password }
        const req = await api.post('/auth/login', request)
        store.dispatch({ type: ActionTypes.AUTH.LOGIN, payload: {...req.data, data: jwt_decode(req.data.access_token)} })
        return req.data
    }

    static logout = async () => {
        store.dispatch({ type: ActionTypes.AUTH.LOGOUT })
    }

    static register = async (data) => {
        const req = await api.post('/auth/register', data)
        return req.data
    }

    static disable = async (id) => {
        const req = await api.put(`/auth/disable/${id}`)
        return req.data
    }

    static enable = async (id) => {
        const req = await api.put(`/auth/enable/${id}`)
        return req.data
    }

    static list_all = async (cancelToken, page, filter) => {
        const req = await api.get('/auth', {
            params:{
                page,
                filter,
            },
            cancelToken: cancelToken.token
        })
        return req.data
    }

}