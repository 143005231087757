
class CartaInadimplenciaMensal{
    file = null
    constructor(filename, file) {
        this.file = file
        this.filename = filename
    }

    async parse(){
        const lines = this.file.split('\n');
        const lines1 = lines.filter(l => l.startsWith('1'))
        return [{arquivo: this.filename, total: lines1.length, tipo: 'mensal'}]
    }
}

export default CartaInadimplenciaMensal