import React, { useState, useEffect } from 'react'
import { Header, Segment, Dimmer, Loader} from 'semantic-ui-react'
import Api from '../../Api';
import { isArray } from 'is-what';
import Produto from './Produto';

const Prazos = props => {
    const [dados, setDados] = useState(null);
    const [loading, setLoading] = useState(false)

    async function load(cancelToken){
        setLoading(true)
        try{
            const dados = await Api.Prazo.getProdutos(cancelToken)
            setDados(dados);
        } catch(erro){
            setDados(null);
        }
        setLoading(false);  
    }

    useEffect(() => {
        const cancelToken = Api.Prazo.getCancelToken()
        load(cancelToken);
        return () => cancelToken.cancel();
    }, [])

    return (
        <>
            <Header as="h2" attached="top">
                <Header.Content>
                    Controle de prazos
                </Header.Content>
            </Header>
            <Segment  attached padded>
                {
                    isArray(dados) && dados.map(produto => <Produto key={produto._id} produto={produto} />)
                }
                <Dimmer active={loading} inverted>
                    <Loader>Carregando</Loader>
                </Dimmer>
            </Segment>
        </>
    )
}

export default Prazos