import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Grid, Header, Label, Segment } from "semantic-ui-react";
import RelatorioDocsApi from "../../Api/relatorio-docs";
import "./style.css";
const DetalheDoc = (props) => {
  const [registro, setRegistro] = useState(null);

  const [loading, setLoading] = useState(false);

  const consultarRegistro = async () => {
    try {
      if (props.match.params.id) {
        setLoading(true);

        const response = await RelatorioDocsApi.getById(props.match.params.id);

        console.log(response);
        setRegistro(response);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    consultarRegistro();
  }, [props.match]);

  return (
    <>
      <div className="comprovante">
        <Segment basic padded loading={loading}>
          <Header as="h2" attached="top" content="Informações sobre o Doc" />
          {registro && (
            <>
              <Segment>
                <Header
                  as="h5"
                  attached="top"
                  content="Dados da Remessa"
                  dividing
                  style={{ backgroundColor: "#ffffb3", fontWeigth: "normal" }}
                />
                <br />
                <label>Arquivo: {registro.Arquivo}</label>
                <br />
                <br />
                <label>
                  Job: {registro.IDJob}.{registro.IDDoc} -{" "}
                  {registro.jobDetails?.Arquivo}
                </label>
                <br />
                <br />
                <label>
                  Recebido:{" "}
                  {registro.jobDetails
                    ? `${moment(registro.jobDetails.Data).format(
                        "DD/MM/YYYY"
                      )} as  ${+registro.jobDetails.Hora.split(":")[0] - 3}:${
                        registro.jobDetails.Hora.split(":")[1]
                      }`
                    : ""}
                </label>
              </Segment>
              <Segment>
                <Header
                  as="h5"
                  attached="top"
                  content="Dados do Doc"
                  dividing
                  style={{ backgroundColor: "#ffffb3", fontWeigth: "normal" }}
                />
                <br />
                <Label color="black">
                  Vencimento:{" "}
                  {moment(registro.DataVencimento)
                    .add(3, "hour")
                    .format("DD/MM/YYYY")}
                </Label>
                &nbsp;
                <Label color="black">Valor: {registro.BOLValor}</Label>
                {registro.Modelo != "LOC" && (
                  <>
                    <br />
                    <br />
                    <label>Condomínio: {registro.EnderNomeCondominio}</label>
                  </>
                )}
                <br />
                <br />
                <label>
                  {registro.Modelo === "LOC" ? "Locatário" : "Condômino"}:{" "}
                  {registro.EnderNomePessoa}
                </label>
              </Segment>

              <Segment>
                <Header
                  as="h5"
                  attached="top"
                  content="Dados de Envio"
                  dividing
                  style={{ backgroundColor: "#ffffb3", fontWeigth: "normal" }}
                />
                <br />
                <label>
                  Tipo de Envio:{" "}
                  {registro.Entregador === "EM"
                    ? "E-mail"
                    : registro.Entregador !== "EM" && registro.eMail
                    ? "Misto - Papel e E-mail"
                    : "Impressão em Papel"}
                </label>
                {(registro.Entregador === "EM" ||
                  (registro.Entregador !== "EM" && registro.eMail)) && (
                  <>
                    <br />
                    <br />
                    <br />
                    <label>Email: {registro.eMail}</label>
                    <br />
                    <br />
                    <label>
                      Enviado: &nbsp;
                      {registro.Sent ? (
                        <>
                          {`${
                            registro.SentData &&
                            registro.DvrStatus &&
                            registro.DvrStatus.includes("SendGrid")
                              ? moment(registro.SentData, "MM/DD/YYYY").format(
                                  "DD/MM/YYYY"
                                )
                              : registro.SentData || ""
                          } ${
                            registro.SentHora
                              ? ` as  ${+registro.SentHora.split(":")[0]}:${
                                  registro.SentHora.split(":")[1]
                                }`
                              : ""
                          }`}
                        </>
                      ) : (
                        "Não"
                      )}
                    </label>
                    <br />
                    <br />
                    <label>
                      Entregue:&nbsp;
                      {registro.DvrData ? (
                        <>
                          {registro.DvrData
                            ? `${
                                registro.DvrStatus &&
                                registro.DvrStatus.includes("SendGrid")
                                  ? moment(
                                      registro.DvrData,
                                      "MM/DD/YYYY"
                                    ).format("DD/MM/YYYY")
                                  : registro.DvrData
                              }   as  ${+registro.DvrHora.split(":")[0]}:${
                                registro.DvrHora.split(":")[1]
                              }`
                            : ""}
                          {registro.DvrStatus ? " - " + registro.DvrStatus : ""}
                          {registro.DvrErro ? " - " + registro.DvrErro : ""}
                        </>
                      ) : (
                        "Não"
                      )}
                    </label>
                    <br />
                    <br />
                    <label>
                      Lido: &nbsp;
                      {registro.Conf ? (
                        <>
                          {`${registro.ConfData}   as  ${
                            +registro.ConfHora.split(":")[0] - 3
                          }:${registro.ConfHora.split(":")[1]}`}
                          {/* {registro.ConfData
                            ? moment(registro.ConfData, "MM/DD/YYYY").format(
                                "DD/MM/YYYY"
                              ) +
                              " as " +
                              registro.ConfHora
                            : ""} */}
                        </>
                      ) : (
                        "Não"
                      )}
                    </label>
                  </>
                )}
              </Segment>
              <Segment className="hide-print">
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Button
                        content="Imprimir"
                        icon="print"
                        onClick={() => window.print()}
                        color="black"
                      ></Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </>
          )}
        </Segment>
      </div>
    </>
  );
};

export default DetalheDoc;
