import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, Header, Segment } from 'semantic-ui-react';
import Api from '../../Api';
import { MAIN_COLOR } from '../../environment';
import ShutterSelector from '../../Helpers/ShutterSelector/ShutterSelector';

const data = [
    { name: 'Mauricio Bonetti Tardetti', email: 'mauricio@softkuka.com.br', codigoCondominio: '', vinculo: 'aluguel' },
    { name: 'Ronaldo Bicca', email: 'ronaldo@softkuka.com.br', codigoCondominio: '', vinculo: 'aluguel' },
    { name: 'Kalil K. Vasconcelos', email: 'kalil@softkuka.com.br', codigoCondominio: '', vinculo: 'aluguel' },
    { name: 'Samuel Martins de Oliveira', email: 'samuel@softkuka.com.br', codigoCondominio: '', vinculo: 'aluguel' },
    { name: 'Vinicius Ferreira', email: 'vinicius@softkuka.com.br', codigoCondominio: '', vinculo: 'aluguel' },
    { name: 'Pablo Dal molin', email: 'pablo@softkuka.com.br', codigoCondominio: '', vinculo: 'aluguel' },
    { name: 'Cristiano de Oliveira', email: 'cristiano@softkuka.com.br', codigoCondominio: '', vinculo: 'aluguel' },
]

const CriadorCampanha = (props) => {
    
    const { id } = useParams();
    
    const [campanha, setCampanha] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory()

    const shutter = useRef();
    
    const loadTemplate = useCallback( async (id) => {
        try {
            const data = await Api.CampanhaEmail.getCampanhaPorId(id);
            setCampanha(data);
        } catch(error) {
            alert("Erro ao carregar o template")
        }
    }, [])

    useEffect(() => {
        if(shutter.current) {
            shutter.current.setRegistros(data)
        }
    }, [shutter])

    useEffect(() => {
        if(id) {
            loadTemplate(id)
        }
    }, [id, loadTemplate])

    const submit = useCallback(async () => {
        try {
            setLoading(true)
            const ret = await Api.CampanhaEmail.criarCampanha(id, { resumo: { emails: shutter?.current?.getSelecionados() } });
            if(ret) {
                window.alert("Campanha criada com sucesso!")
                history.goBack();
            }
        } finally{
            setLoading(false)
        }
    }, [id, history, shutter])

    return <div style={{padding: 10, margin: 20, marginTop: 30}}>
        <Header as="h1" >
            <Header.Content>
                Configuração de campanha - Modelo {campanha?.name}
            </Header.Content>
        </Header>
        <Segment loading={loading}>
            <Grid divided>
                <Grid.Column width={13}>
                    <Header as="h2">Seleção de destinatários</Header>
                    <Header as="h4" content="Filtros disponíveis"></Header>
                    <div>
                        <ShutterSelector ref={shutter} renderItem={(item) => item.name} keyExtractor={item => item.email} />
                    </div>
                    <br/>
                    <Button content="Criar Campanha" fluid color={MAIN_COLOR} basic onClick={submit}></Button>
                </Grid.Column>
                <Grid.Column width={3} textAlign="left">
                    <Header as="h4" content="Prévia do email" subheader="Alguns elementos do email criado podem não estar sendo exibidos corretamente na imagem. Para uma visualização correta abra pelo editor."></Header>
                    <img alt='prévia email' style={{width: '100%'}} src={campanha?.previa_template}></img>
                </Grid.Column>
            </Grid>
        </Segment>
    </div>
}

export default CriadorCampanha;
