import React, { useState, useEffect } from 'react'
import { Modal, Header, Form } from 'semantic-ui-react'
import Api from '../../Api'

const ConfimacaoCancelamento = props => {
    const { registro, condominio, close, closeFinish } = props
    const [cancelamento, setCancelamento] = useState({
        codigo: '',
        condominio: '',
        solicitante: '',
        motivo: '',
    })
    const [loading, setLoading] = useState(false);

    const changeValue = (e, { name, value }) => {
        setCancelamento({ ...cancelamento, [name]: value })
    }
    
    const confirmarCancelamento = async () => {
        setLoading(true)
        try {
            const cancel = await Api.Envio.cancelaCondominio(cancelamento);
            closeFinish(cancel)
        } catch(error) {

        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        if(registro && condominio){
            setCancelamento(c => ({ ...c, codigo: registro.codigo, condominio: condominio.codigoCondominio }))
        }
        return () => setCancelamento(c => ({
            codigo: '',
            condominio: '',
            solicitante: '',
            motivo: '',
        }))
    }, [registro, condominio])
    
    if (!registro || !condominio) {
        return <></>
    }

    return <Modal open onClose={close} closeIcon>
        <Modal.Header> Confirmação de cancelamento </Modal.Header>
        <Modal.Content>
            <Form loading={loading} onSubmit={confirmarCancelamento}>
                <Form.Field required>
                    <label>Código do envio</label>
                    <Header content={cancelamento.codigo} />
                </Form.Field>
                <Form.Field required>
                    <label>Condomínio</label>
                    <Header content={cancelamento.condominio} />
                </Form.Field>
                <Form.Field required>
                    <label>Solicitante</label>
                    <Form.Input required name="solicitante" value={cancelamento.solicitante} onChange={changeValue} />
                </Form.Field>
                <Form.Field required>
                    <label>Motivo</label>
                    <Form.Input required name="motivo" value={cancelamento.motivo} onChange={changeValue} />
                </Form.Field>
                <Form.Group>
                    <Form.Button content="Confirmar" color="black" icon="check circle" />
                    <Form.Button type="button" onClick={close} content="Cancelar" color="black" basic icon="times circle" />
                </Form.Group>
            </Form>
        </Modal.Content>
    </Modal>
}

export default ConfimacaoCancelamento