import JSZip from 'jszip'
import { PDFDocument } from 'pdf-lib';
const pattern = /^.{2}(\d{2})(\d{5})(\w{2})(\w{5})(\w{5})(.{15})(\w{4})(\w{2})(.{40})(.{40})(.{71})(.{9})(.{30})(.{30})(\w{2})/gi
const parts = {
    codigoCondominio: 2,
    entregador: 3,
    condominio: 5,
    unidade: 6,
    local: 7,
    nome: 9,
    aosCuidados: 10,
    endereco: 11,
    cep: 12,
    bairro: 13,
    cidade: 14,
    uf: 15,
}

class Cartas{
    file = null
    constructor(filename, file) {
        this.file = file
        this.filename = filename
    }

    async parse(){
        const zip = await new JSZip()
        await zip.loadAsync(this.file)
        const files = zip.files;

        const filenames = Object.keys(files);

        const hasPdf = filenames.filter(f => f.endsWith('.pdf')).length > 0;
        const dadFiles = filenames.filter(f => f.toLowerCase().endsWith('.dad'));
        const hasDad = dadFiles.length > 0;

        let numPaginas;
        try{
            const pdfFile = filenames.find(f => f.endsWith('.pdf'))
            const buffer = await files[pdfFile].async('uint8array')
            const document = await PDFDocument.load(buffer)
            numPaginas = document.getPageCount()
        } catch(erro){ }



        if(!hasDad){
            throw new Error("Arquivo de dados não encontrado");
        }

        if(!hasPdf){
            throw new Error("Arquivo PDF não encontrado");
        }

        const linhas = [];
        for(let i = 0; i < dadFiles.length; i++){
            const dadName = dadFiles[i];
            const file = files[dadName];
            const data = await file.async('text')

            const linhasArquivo = data.split('\n');

            linhasArquivo.forEach(linha => {
                if(linha){
                    const sections = linha.split(pattern);
                    if(sections && sections.length){
                        const obj = Object.keys(parts).reduce((acc, chave) => {
                            return {...acc, [chave]: sections[parts[chave]] && sections[parts[chave]].trim()}
                        }, {})
                        linhas.push({...obj, numPaginas})
                    }
                }
            })
        }

        return linhas
    }
}

export default Cartas