import JSZip from 'jszip'
const pattern = /^EX(.{5})(.{3}).{2}(.{2})(.{5})(.{5})(.{3})(.{15}).{2}(.{40})(.{40})(.{71})(.{9})(.{30})(.{30})(.{2})/gi
const parts = {
    codigoCondominio: 1,
    entregador: 3,
    condominio: 5,
    unidade: 7,
    bloco: 6,
    nome: 8,
    contato: 9,
    endereco: 10,
    cep: 11,
    bairro: 12,
    cidade: 13,
    uf: 14,
}

const extratoAnaPattern = { pattern: /^EXP(\d{4})(\d{2}).ZIP$/gi, tipo: 'Extrato - Proprietário' }


const tipos = [extratoAnaPattern]
class ExtratoProprietario{
    file = null
    constructor(filename, file) {
        this.file = file
        this.filename = filename
        this.tipo = tipos.find(t => filename.match(t.pattern)).tipo
    }

    async parse(){
        const zip = await new JSZip()
        await zip.loadAsync(this.file)
        const files = zip.files;

        const filenames = Object.keys(files);

        const hasExt = filenames.filter(f => f.toLowerCase().endsWith('.ext')).length > 0;
        const dadFiles = filenames.filter(f => f.toLowerCase().endsWith('.dad'));
        const hasDad = dadFiles.length > 0;


        if(!hasDad){
            throw new Error("Arquivo de dados não encontrado");
        }

        if(!hasExt){
            throw new Error("Arquivo EXT não encontrado");
        }

        const linhas = [];
        for(let i = 0; i < dadFiles.length; i++){
            const dadName = dadFiles[i];
            const file = files[dadName];
            const data = await file.async('text')

            const linhasArquivo = data.split('\n');

            linhasArquivo.forEach(linha => {
                if(linha){
                    const sections = linha.split(pattern);
                    if(sections && sections.length){
                        const obj = Object.keys(parts).reduce((acc, chave) => {
                            return {...acc, [chave]: sections[parts[chave]] && sections[parts[chave]].trim()}
                        }, {})
                        linhas.push({...obj, tipo: this.tipo})
                    }
                }
            })
        }

        return linhas
    }
}

export default ExtratoProprietario