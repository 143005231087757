import Actions from './../ActionTypes'

function providerReducer(state = {}, action) {
  switch(action.type) {
    case Actions.PROVIDER.LOAD_DEFAULT:
      return action.payload;
    case Actions.PROVIDER.UNLOAD_DEFAULT:
      return {}
    default: 
      return state;
  }
}

export default providerReducer;