import React, {useState} from 'react'
import { Button, Form, Grid, Header, Image, Segment, Message, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import Api from './../../Api';
import routes from '../../Navigator/routes';
import { MAIN_COLOR } from '../../environment';

const LoginForm = ({history}) => {
    const [user, setUser] = useState({username: '', password: ''});
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState(null);

    const signin = () => {
        const authenticate = async () => {
            setLoading(true);
            try{
                const response = await Api.Auth.login(user.username, user.password);
                if(response){
                    history.push(routes.INICIO)
                }
            } catch(error){
                setErro(true);
                setLoading(false);
            }
        }
        authenticate();
    }

    const handleFormChange = (name) => (event) => {
        setUser({...user, [name]: event.target.value});
    }

    return (
        <>
            <div style={{ position: "fixed", height: '100vw', width: '100vw', background: '#D3CBBD'}}>
            </div>
            <div style={{ height: '100vh'}}>
                <Grid textAlign='center' style={{ height: '100%' }}  verticalAlign='middle' centered>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        
                        <Form error={erro !== null} loading={loading} size='large'>
                            <Segment padded>
                                <Header as='h1'  textAlign='center'>
                                <Image style={{ marginTop: 30, marginBottom: 50, width: '80%', objectFit: 'contain' }} src={`${process.env.PUBLIC_URL}/logo.png`} />
                                </Header>
                                <Form.Input value={user.username} size="large" onChange={handleFormChange('username')} fluid icon='user' iconPosition='left' placeholder='Usuário' />
                                <Form.Input
                                    value={user.password}
                                    onChange={handleFormChange('password')}
                                    fluid
                                    size="large"
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Senha'
                                    type='password'
                                />
                                <Message error content="Usuário ou senha inválidos" />
                                <Button size="huge" color={MAIN_COLOR} disabled={loading} loading={loading} fluid onClick={signin}>
                                    <Icon name="sign-in"></Icon>Entrar
                                </Button>
                                <div style={{clear: 'both'}}/>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        </>
    )
}

export default withRouter(LoginForm)