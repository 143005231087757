import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useHistory } from "react-router-dom";
import { Button, Dropdown, Header, Segment, Table } from 'semantic-ui-react';
import Api from '../../Api';
import { MAIN_COLOR } from '../../environment';
import { CRIADOR_CAMPANHA_EMAIL, CRIADOR_EMAILS, EDICAO_EMAILS } from '../../Navigator/routes';

const CampanhasEmail = (props) => {

    const history = useHistory()
    

    const enviarEmailTeste = useCallback(async (id) => {
        try  {
            const to = window.prompt("Informe o email de destino");
            if(to) {
                await Api.CampanhaEmail.enviarEmailTeste(id, to)
            }
            alert("Email enviado com sucesso");
        } catch(err) {

        }
    }, [])

    const actions = React.useMemo(() => {
        return [
            { key: 'mail', icon: 'mail', text: 'Gerar campanha a partir do modelo', onClick:(id) => history.push(CRIADOR_CAMPANHA_EMAIL.replace(":id", id))},
            { key: 'send', icon: 'send', text: 'Enviar e-mail de teste', onClick:(id) => enviarEmailTeste(id)},
        ]
    }, [enviarEmailTeste, history])


    const [campanhas, setCampanhas] = useState([]);
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async (page, cancelToken) => {
        //setPage(page);
        setLoading(true);
        try {
            const data = await Api.CampanhaEmail.getCampanhas(page, cancelToken);
            const { docs } = data;
            setCampanhas(docs);
        } finally{
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        const cancelToken = Api.CampanhaEmail.getCancelToken();
        fetchData(1, cancelToken)
        return () => cancelToken.cancel();
    }, [fetchData])

    return <>
        <Header content="Campanhas email" as="h1" attached="top">
            <Header.Content>
                Campanhas email
            </Header.Content>
            <Link to={CRIADOR_EMAILS}>
                <Button color={MAIN_COLOR} basic size="large" floated="right" icon="add circle" content="Nova campanha"></Button>
            </Link>
        </Header>
        <Segment attached="bottom" loading={loading}>
            <Table striped celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell style={{width: 150}}>Criado em</Table.HeaderCell>
                        <Table.HeaderCell style={{width: 150}}>Atualizado em</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" style={{width: 100}}>Preview</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" style={{width: 200}}>Ações</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {campanhas.map(row => <Table.Row key={row._id}>
                        <Table.Cell>{row.name}</Table.Cell>
                        <Table.Cell>
                            <Moment format="DD/MM/YYYY HH:mm">
                                {row.created_at}
                            </Moment>
                        </Table.Cell>
                        <Table.Cell>
                            <Moment format="DD/MM/YYYY HH:mm">
                                {row.updated_at}
                            </Moment>
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            <img alt="Prévia do email" src={row.previa_template} width="100"></img>
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            <Button.Group basic color={MAIN_COLOR}>
                                <Link to={EDICAO_EMAILS.replace(":id", row._id)}>
                                    <Button>
                                        Editar
                                    </Button>
                                </Link>
                                <Dropdown
                                className='button icon'
                                floating
                                trigger={<></>}
                                >
                                    <Dropdown.Menu>
                                        {actions.map(action => <Dropdown.Item icon={action.icon} key={action.key} text={action.text} content={action.content} onClick={() => action.onClick(row._id)}>
                                            
                                        </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>)}
                </Table.Body>
            </Table>
        </Segment>
    </>
}

export default CampanhasEmail;
