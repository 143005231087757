import Axios from "axios";
import store from "./../Store";

// export const baseURL = "http://localhost:8188/";
//export const baseURL = "http://creditoreal.softkuka.com.br:8091/";
export const baseURL = "https://creditoreal-api.softkuka.com.br";

const api = Axios.create({
  baseURL,
});

api.interceptors.request.use(async (config) => {
  if (store.getState().auth) {
    const { access_token } = store.getState().auth;
    config.headers.authorization = `Bearer ${access_token}`;
  }
  return config;
});

export default api;
