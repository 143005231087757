import api from './api'
import Axios from 'axios'

export default class CampanhaEmail {

    static getCancelToken = () => Axios.CancelToken.source()

    static getCampanhas = async (page, cancelToken) => {
        const req = await api.get(`/campanhas`, {
            params:{ page: page ?? 1 },
            cancelToken: cancelToken.token
        });
        return req.data
    }

    static getCampanhaPorId = async (id) => {
        const req = await api.get(`/campanhas/retrieve/${id}`);
        return req.data
    }

    static enviarEmailTeste = async (id, to) => {
        const req = await api.post(`/campanhas/email-teste/${id}`, { to });
        return req.data
    }

    static criarCampanha = async (id, obj) => {
        const req = await api.post(`/campanhas/criar-campanha/${id}`, obj);
        return req.data
    }

    static createOrUpdateCampanha = async (id, campanha) => {
        let url = `/campanhas`;
        if(id) {
            url = `${url}/${id}`
        }
        const req = await api.post(url, campanha);
        return req.data
    }
}