import { createStore, combineReducers } from 'redux';
import reducers from './Reducers'
import { loadState, saveState } from '../Helpers/localstorage';

const loadedState = loadState()

const store = createStore(
    combineReducers({
        auth: reducers.auth,
        provider: reducers.provider,
    }),
    loadedState
);

store.subscribe(() => {
    const state = store.getState();
    saveState(state);
});

export default store