import Boleto from "./Boleto"
import BoletoAluguel from "./BoletoAluguel"
import CartaInadimplenciaMensal from "./CartaInadimplenciaMensal"
import Cartas from "./Cartas"
import Contracheque from "./Contracheque"
import Contraponto from "./Contraponto"
import Extrato from "./Extrato"
import ExtratoProprietario from "./ExtratoProprietario"
import Inadimplencia from "./Inadimplencia"

const boletoPattern = {pattern: /(\w{2})_REG_(\d{4})(\d{2})[^C].C(\d{3})/gi, tipo: 'Boleto'}
const boletoAcordoPattern = {pattern: /(\w{2})_REG_(\d{4})(\d{2})C.C(\d{3})/gi, tipo: 'Boleto acordo'}
const boletoAluguelPattern = {pattern:/^(\w{2})_(\w+)_(.*)(\d{4})(\d{2})(\w).L(\d{3})/gi, tipo: 'Boleto - Aluguel'}
const ataPattern = {pattern: /^(ATA)_(\d{5})_(.*)_(AGE|AGO|AGJ)_(\d{2})(\d{2})(\d{4})/gi, tipo: 'Cartas'}
const convocacaoPattern = {pattern: /^(CON)_(\d{5})_(.*)_(AGE|AGO|AGJ)_(\d{2})(\d{2})(\d{4})_(AR|SIMPLES|REGISTRADO)/gi, tipo: 'Cartas'}
const circularPattern = {pattern: /(CIR)_(\d{5})_([^_]*)_?(.*)?_(AR|SIMPLES|REGISTRADO)/gi, tipo: 'Cartas'}
const apresentacaoPattern = {pattern: /^(APR)_(\d{5})(.*)_(.*)?/gi, tipo: 'Cartas'}
const contrachequePattern = {pattern: /^CONTRA_(\w{2})_(.{2})/gi, tipo: 'Contracheque'}
const contrapontoPattern = {pattern: /^PONTO_(\w{2})_(.{2})/gi, tipo: 'Ponto'}
const cartaInadMensal = { pattern: /^CARTAINAD_MENSAL_(\d*).DAD/gi, tipo: 'Inadimplência - Mensal' }
const extratoAnaPattern = { pattern: /^EXT(\d{4})(\d{2})(A).ZIP$/gi, tipo: 'Extrato - Analítico' }
const extratoSintPattern = { pattern: /^EXT(\d{4})(\d{2})(S).ZIP$/gi, tipo: 'Extrato - Sintético' }
const extratoPdfPattern = { pattern: /^EXT_PDF_(\d{5})_(.*).ZIP$/gi, tipo: 'Extrato - PDF' }
const extratoProprietarioPattern = { pattern: /^EXP(\d{4})(\d{2}).ZIP$/gi, tipo: 'Extrato - Proprietário' }
const cartaInadimplencia = { pattern: /^(\w{2})_CARTAINADCOND_(\d*).txt/gi, tipo: 'Inadimplência' }

const patterns = [
    boletoPattern,
    boletoAcordoPattern,
    ataPattern,
    convocacaoPattern,
    circularPattern,
    apresentacaoPattern,
    boletoAluguelPattern,
    contrachequePattern,
    cartaInadMensal,
    extratoAnaPattern,
    extratoSintPattern,
    extratoPdfPattern,
    cartaInadimplencia,
    extratoProprietarioPattern,
    contrapontoPattern
]

class MultiParser{

    constructor(file, data){
        this.base = file
        this.file = data
        this.filename = file.name
        this.extension = file.name.split('.')[file.name.split('.').length-1]

        const type = patterns.find(p => this.filename.match(p.pattern))

        if(!type){
            throw new Error("Padrão inválido")
        }

        this.type = type.tipo
    }

    async parse() {
        switch(this.type) {
            case boletoPattern.tipo: {
                const b = new Boleto(this.filename, this.file)
                return { tipo: 'boleto', dados: await b.parse() }
            }
            case boletoAcordoPattern.tipo: {
                const b = new Boleto(this.filename, this.file)
                return { tipo: 'boleto', dados: await b.parse() }
            }
            case boletoAluguelPattern.tipo: {
                const b = new BoletoAluguel(this.filename, this.file)
                return { tipo: 'boletoAluguel', dados: await b.parse() }
            }
            case 'Cartas': {
                const b = new Cartas(this.filename, this.base)
                return { tipo: 'cartas', dados: await b.parse() }
            }
            case extratoPdfPattern.tipo: {
                const b = new Cartas(this.filename, this.base)
                return { tipo: 'cartas', dados: await b.parse() }
            }
            case contrachequePattern.tipo: {
                const b = new Contracheque(this.filename, this.file)
                return { tipo: 'contracheque', dados: await b.parse() }
            }
            case contrapontoPattern.tipo: {
                const b = new Contraponto(this.filename, this.file)
                return { tipo: 'contraponto', dados: await b.parse() }
            }
            case cartaInadMensal.tipo: {
                const b = new CartaInadimplenciaMensal(this.filename, this.file)
                return { tipo: 'inadimplencia', dados: await b.parse() }
            }
            case cartaInadimplencia.tipo: {
                const b = new Inadimplencia(this.filename, this.file)
                return { tipo: 'inadimplencia', dados: await b.parse() }
            }
            case extratoSintPattern.tipo: {
                const b = new Extrato(this.filename, this.base)
                return { tipo: 'extrato', dados: await b.parse() }
            }
            case extratoAnaPattern.tipo: {
                const b = new Extrato(this.filename, this.base)
                return { tipo: 'extrato', dados: await b.parse() }
            }
            case extratoProprietarioPattern.tipo:{
                const b = new ExtratoProprietario(this.filename, this.base)
                return { tipo: 'extrato', dados: await b.parse() }
            }
            default: {
                throw new Error("Parser não encontrado")
            }
        }

    }

}

export default MultiParser