import Axios from "axios";
import api from "./api";

export default class Producao {
  static getCancelToken = () => Axios.CancelToken.source();

  static relatorio_excel = async (periodo, cancelToken) => {
    const req = await api.get(`/producao/relatorio-excel`, {
      params: { ...periodo },
      cancelToken: cancelToken.token,
    });
    return req.data;
  };

  static relatorio_boletos = async (periodo, cancelToken) => {
    const req = await api.get(`/producao/relatorio-boletos`, {
      params: { ...periodo },
      cancelToken: cancelToken.token,
    });
    return req.data;
  };
}
