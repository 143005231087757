import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";
import routes from "../../Navigator/routes";
import RelatorioCartasApi from "./../../Api/relatorio-cartas";
import CsvViewerCartas from "./CsvViewerCartas";

const RelatorioGeracaoCartas = (props) => {
  const { history } = props;
  moment.locale("pt-BR");
  const [periodo, setPeriodo] = useState({
    dataInicio: null,
    dataFim: null,
  });

  const [filtros, setFiltros] = useState({ condominio: "" });
  const [foco, setFoco] = useState(null);

  const [registros, setRegistros] = useState(null);

  const [loading, setLoading] = useState(false);

  const consultar = async () => {
    try {
      setLoading(true);
      const response = await RelatorioCartasApi.consultar(periodo, filtros);

      setRegistros(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setRegistros(null);
    }
  };

  const exportCSV = () => {
    window.URL = window.webkitURL || window.URL;

    console.log(registros);

    let regs = registros.replace("_", ". ").replace("_", ". ");

    var csvFile = new Blob(["\uFEFF" + regs], {
      type: "text/csv; charset=utf-8",
    });

    var link = document.createElement("a");

    link.setAttribute("href", window.URL.createObjectURL(csvFile));

    link.setAttribute("download", "relatorio.csv");

    link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");

    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  const navigateToDetails = (doc) => {
    window.open(
      routes.DETALHE_CARTAS.replace(":id", doc["IDJob"] + "&" + doc["ID"])
    );
  };

  return (
    <>
      <Header as="h2" attached="top" content="Consulta de Cartas" dividing />
      <Segment attached="bottom">
        <Form loading={loading}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Data Job:</label>
                  <DateRangePicker
                    startDate={periodo.dataInicio} // momentPropTypes.momentObj or null,
                    numberOfMonths={2}
                    minimumNights={0}
                    enableOutsideDays={true}
                    noBorder
                    isOutsideRange={() => false}
                    endDate={periodo.dataFim} // momentPropTypes.momentObj or null,
                    onDatesChange={({ startDate, endDate }) =>
                      setPeriodo({ dataInicio: startDate, dataFim: endDate })
                    } // PropTypes.func.isRequired,
                    focusedInput={foco} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      setFoco(focusedInput || "startDate")
                    } // PropTypes.func.isRequired,
                    startDatePlaceholderText="Início"
                    endDatePlaceholderText="Fim"
                    onClose={() => setFoco(null)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Cod.Condominio:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, condominio: e.target.value })
                    }
                    value={filtros.condominio}
                    placeholder="Código do Condomínio"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Nome:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, nome: e.target.value })
                    }
                    value={filtros.nome}
                    placeholder="Nome"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>E-mail:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, email: e.target.value })
                    }
                    value={filtros.email}
                    placeholder="E-mail"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Economia:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, idEconomia: e.target.value })
                    }
                    value={filtros.idEconomia}
                    placeholder="Economia"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Tipo Envio:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, tipo: e.target.value })
                    }
                    value={filtros.tipo}
                    placeholder="Tipo Envio"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Pedido:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, pedido: e.target.value })
                    }
                    value={filtros.pedido}
                    placeholder="Pedido"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column tablet={16} computer={4} mobile={16}>
                <Form.Field>
                  <label>Pedido:</label>
                  <Form.Input
                    onChange={(e) =>
                      setFiltros({ ...filtros, pedido: e.target.value })
                    }
                    value={filtros.pedido}
                    placeholder="Código do Pedido"
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="right">
                <Button
                  icon="download"
                  content="Relatório"
                  color="black"
                  loading={loading}
                  onClick={() => exportCSV()}
                  type="button"
                />
                <Button
                  icon="search"
                  content="Localizar"
                  color="black"
                  onClick={() => consultar()}
                  loading={loading}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <Divider />

        {registros && (
          <CsvViewerCartas
            data={registros}
            hasLink
            linkKey="ID"
            linkDestFn={navigateToDetails}
            formatDate
            dateFormat="DD/MM/YYYY"
            dateFields="Data Envio"
            showTotal
          />
        )}
      </Segment>
    </>
  );
};

export default RelatorioGeracaoCartas;
