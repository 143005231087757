export const LOGIN = "/login";
export const INICIO = "/";
export const ACOMPANHAMENTO = "/acompanhamento";
export const PRODUCAO = "/producao";

export const USUARIOS = "/usuarios";
export const ENVIO = "/envio";
export const ESTATISTICAS = "/estatisticas";
export const PRAZOS = "/prazos";
export const RELATORIO_PRODUCAO = "/relatorio-producao";
export const RELATORIO_ENVIOS = "/relatorio-envios";
export const CONDOMINIOS = "/condominios";
export const PROTOCOLO = "/protocolo";
export const ENTREGADORES_MANUAIS = "/entregadores-manuais";
export const CONSULTA_INADIMPLENCIAS = "/consulta-inadimplencias";

export const RELATORIO_INADIMPLENCIA = "/relatorio-inadimplencia";

export const RELATORIO_PRODUCAO_ARQUIVOS = "/relatorio-producao-arquivos";

export const CAMPANHAS_EMAIL = "/campanhas-email";
export const CRIADOR_EMAILS = "/criador-emails";
export const EDICAO_EMAILS = "/edicao-email/:id";
export const CRIADOR_CAMPANHA_EMAIL = "/criar-envio/:id";

export const RELATORIO_GERACAO_DOCS = "/relatorio-geracao-docs";
export const RELATORIO_GERACAO_CARTAS = "/relatorio-geracao-cartas";

export const DETALHE_CARTAS = "/detalhe-cartas/:id";
export const DETALHE_DOCS = "/detalhe-docs/:id";
export const DETALHE_INADIMPLENCIA = "/detalhe-inadimplencia/:id";

export const RELATORIO_BOLETOS_ARQUIVO = "/relatorio-boletos-arquivo";

export default {
  INICIO,
  LOGIN,
  ACOMPANHAMENTO,
  USUARIOS,
  ENVIO,
  PRODUCAO,
  ESTATISTICAS,
  PRAZOS,
  RELATORIO_PRODUCAO,
  CONDOMINIOS,
  RELATORIO_ENVIOS,
  CONSULTA_INADIMPLENCIAS,
  PROTOCOLO,
  ENTREGADORES_MANUAIS,
  RELATORIO_INADIMPLENCIA,
  RELATORIO_PRODUCAO_ARQUIVOS,
  CRIADOR_EMAILS,
  CAMPANHAS_EMAIL,
  EDICAO_EMAILS,
  CRIADOR_CAMPANHA_EMAIL,
  RELATORIO_GERACAO_DOCS,
  DETALHE_DOCS,
  RELATORIO_GERACAO_CARTAS,
  DETALHE_CARTAS,
  DETALHE_INADIMPLENCIA,
  RELATORIO_BOLETOS_ARQUIVO,
};
