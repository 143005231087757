import jwt_decode from "jwt-decode";
import React from "react";
import { connect } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Menubar from "../Components/Menubar";


const PrivateRoute = ({ component: Component, auth: usuario, logout, permissions: permissaoNecessaria, fullscreen, cover, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        let authValid = false;

        let jwtToken;
        if(usuario){
          let auth = usuario !== null && usuario !== {};
          jwtToken = auth && jwt_decode(usuario.access_token);

          authValid = jwtToken && jwtToken.exp > (new Date().getTime() + 1) / 1000;
          
          if(authValid) {
            if(Array.isArray(permissaoNecessaria) && !permissaoNecessaria.includes(jwtToken.role)){
              authValid = false;
            }
          }
          
        }
        
        if (!authValid) {
          if(logout){
            logout();
          }
          return (
            <Redirect to={{pathname: "/login", state: { from: props.location }}} />
          )
        }


        if(authValid && cover) {
          return <Component {...rest} {...props} />
        }

        console.log(jwtToken)
        return <>
              <Menubar currentRole={jwtToken && jwtToken.role}>
                { fullscreen &&
                  <Component
                    {...rest}
                    {...props}
                    currentRole={jwtToken?.role}
                  />
                }
                { !fullscreen &&
                  <Container style={{marginTop: 50, width: 1300}}>
                    <Component                      
                      {...rest}
                      {...props}                      
                      currentRole={jwtToken?.role}
                    />
                  </Container>
                }
            </Menubar>
          </>
      }}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});


export default connect(mapStateToProps)(PrivateRoute);