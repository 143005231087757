import Axios from 'axios';
import api from './api';

export default class RelatorioDocsApi {
    
    static getCancelToken = () => Axios.CancelToken.source()

  
    static consultar = async (periodo,filtros) => {

        const cancelToken = Axios.CancelToken.source();

        // const req = await Axios.get(`http://www3.gbgraf.com.br/credito-real-helper/relatorios`, {
        // const req = await Axios.get(`http://172.30.3.136:8098/consulta-docs/relatorios`, {
        // const req = await Axios.get(`http://localhost:8188/consulta-docs/relatorios/docs/`, {
        const req = await api.get(`/consulta-docs/relatorios/docs/`, {
            params:{ 
                ...periodo, 
                ...filtros
            },
            cancelToken: cancelToken.token
        });
        return req.data
    }

    
    static getById = async (id) => {

        const cancelToken = Axios.CancelToken.source();

        const req = await api.get(`/consulta-docs/${id}`, {            
            cancelToken: cancelToken.token
        });
        return req.data
    }

}