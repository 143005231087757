import React, { useState } from 'react'
import { Modal, Table, Header, Button } from 'semantic-ui-react'
import { isArray } from 'util'
import ConfimacaoCancelamento from './ConfirmacaoCancelamento'

const CancelamentoParcial = props => {
    const { registro, closeCancelamento } = props
    const [condominio, setCondominio] = useState(null)

    if (!registro) {
        return <></>
    }

    const close = () => setCondominio(null);
    const closeFinish = (cancel) => {
        setCondominio(null)
        closeCancelamento(cancel)
    };

    const { resumo } = registro

    const cnds = resumo['boleto'].reduce((arr, at) => [...arr, ...at.itens], [])
    const condominios = cnds.reduce((arr, atual) => {
        const cond = arr.find(c => c.codigoCondominio === atual.codigoCondominio)
        if (!cond){
            return [...arr, atual]
        }
        cond.numeroRegistros += atual.numeroRegistros
        return arr
    }, [])

    return <Modal open closeIcon onClose={() => closeCancelamento()}>
        <Modal.Header> Cancelamento parcial </Modal.Header>
        <Modal.Content>
            <Header content="Qual condomínio deseja cancelar?" />
        { condominios.length > 0 && 
                    <>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Código Condomínio</Table.HeaderCell>
                                    <Table.HeaderCell>Condomínio</Table.HeaderCell>
                                    <Table.HeaderCell>Número de boletos</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>Cancelar</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    isArray(condominios) && condominios.map(condominio => {
                                        return (
                                            <Table.Row key={condominio.codigoCondominio}>
                                                <Table.Cell>{condominio.codigoCondominio}</Table.Cell>
                                                <Table.Cell>{condominio.nomeCondominio}</Table.Cell>
                                                <Table.Cell>{condominio.numeroRegistros}</Table.Cell>
                                                <Table.Cell>
                                                    { !condominio.cancelado &&
                                                        <Button fluid circular compact content="Cancelar" onClick={() => setCondominio(condominio)} icon="times circle" color="red" />
                                                    }
                                                    { condominio.cancelado &&
                                                        <Header content="Já cancelado" />
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }                                
                            </Table.Body>
                        </Table>
                    </>
                }
                <ConfimacaoCancelamento registro={registro} condominio={condominio} close={close} closeFinish={closeFinish} />
        </Modal.Content>
    </Modal>
}

export default CancelamentoParcial