import { isArray } from 'is-what';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Button, Dimmer, Form, Header, Loader, Message, Segment, Table } from 'semantic-ui-react';
import Api from '../../Api';
import { MAIN_COLOR } from '../../environment';
import AutoCompleteCondominio from './AutoCompleteCondominio';

const EntregadoresManuais = props => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        condominio: ''
    })
    const [error, setError] = useState(null)

    async function load(cancelToken){
        setLoading(true)
        try{
            const dados = await Api.EntregaManual.getRegras(cancelToken)
            setDados(dados);
        } catch(erro){
            setDados([]);
        }
        setLoading(false);  
    }

    const submit = async () => {
        setError(null)
        try {
            if(!form.condominio) {
                throw new Error('Condomínio deve ser informado')
            }
            const saved = await Api.EntregaManual.adicionarRegra(form.condominio)
            setDados([...dados, saved])
            setForm({ condominio: '' })
        } catch (error) {
            if(error.response && error.response.data) {
                const { response:{ data }} = error;
                setError(data.error)
                return
            }
            setError(error.message)
        }
    }

    const deleteRegra = async id => {
        try {
            await Api.EntregaManual.removerRegra(id)
            setDados(dados.filter(d => d._id !== id))
        } catch(error) {
            setError('Erro ao remover regra')
        }
    }

    useEffect(() => {
        const cancelToken = Api.EntregaManual.getCancelToken()
        load(cancelToken);
        return () => cancelToken.cancel();
    }, [])

    const changeForm = (e, {name,value}) => {
        setForm({...form, [name]: value})
    }

    return (
        <>
            <Header as="h2" attached="top">
                <Header.Content>
                    Entregadores manuais
                </Header.Content>
                <Header.Subheader>
                    Condomínios registrados para entrega manual terão seu entregador padrão (PT/LC) desconsiderado.
                </Header.Subheader>
            </Header>
            <Segment attached>
                <Header as="h4">Nova regra</Header>
                <Form error={error !== null} onSubmit={submit}>
                    <Form.Field required>
                        <label>Condomínio</label>
                        <AutoCompleteCondominio name="condominio" required value={form.condominio} onChange={changeForm} />
                    </Form.Field>
                    <Message error content={error} />
                    <Form.Button icon="check circle" color={MAIN_COLOR} content="Adicionar" fluid  />
                </Form>
            </Segment>
            <Segment  attached padded>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Codígo Condomínio</Table.HeaderCell>
                            <Table.HeaderCell>Nome condomínio</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" width={2}>Data registro</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" width={1}>Remover</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { isArray(dados) && dados.map(linha => (
                            <Table.Row key={linha.codigo}>
                                <Table.Cell>{linha.codigo}</Table.Cell>
                                <Table.Cell>{linha.nome}</Table.Cell>
                                <Table.Cell textAlign="right"><Moment format="DD/MM/YYYY HH:mm">{linha.created_at}</Moment></Table.Cell>
                                <Table.Cell textAlign="right">
                                    <Button icon="trash" color={MAIN_COLOR} basic onClick={() => deleteRegra(linha._id)} />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Dimmer active={loading} inverted>
                    <Loader>Carregando</Loader>
                </Dimmer>
            </Segment>
        </>
    )
}

export default EntregadoresManuais