import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Container,
  Dropdown,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import routes from "../../Navigator/routes";
import ActionTypes from "../../Store/ActionTypes";
import { MAIN_COLOR } from "./../../environment";
// Heads up!
// We using React Statrestic to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {
    usuario: {},
  };

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  componentDidMount() {}

  navigate = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { children, currentRole, usuario } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment textAlign="center" vertical className="hide-print">
            <Menu
              fixed="top"
              inverted={!fixed}
              color={!fixed ? MAIN_COLOR : null}
              size="huge"
            >
              {!["assessor"].includes(currentRole) && (
                <Container fluid>
                  <Link to={routes.INICIO}>
                    <Menu.Item as="span" style={{ width: "100%" }} link>
                      <Icon name="send" />
                      Envio
                    </Menu.Item>
                  </Link>
                  {
                    <Link to={routes.ACOMPANHAMENTO}>
                      <Menu.Item as="span" link>
                        <Icon name="server" />
                        Acompanhamento
                      </Menu.Item>
                    </Link>
                  }
                  {["administrador", "root"].includes(currentRole) && (
                    <Link to={routes.USUARIOS}>
                      <Menu.Item as="span" link>
                        <Icon name="users" />
                        Usuarios
                      </Menu.Item>
                    </Link>
                  )}
                  {["producao", "root"].includes(currentRole) && (
                    <Link to={routes.PRODUCAO}>
                      <Menu.Item as="span" link>
                        <Icon name="cogs" />
                        Produção
                      </Menu.Item>
                    </Link>
                  )}
                  {/* <Link to={routes.CAMPANHAS_EMAIL}>
                  <Menu.Item as="span" link>
                    <Icon name="mail" />
                    Campanhas email
                  </Menu.Item>
                </Link> */}
                  {currentRole === "root" && (
                    <Link to={routes.ESTATISTICAS}>
                      <Menu.Item as="span" link>
                        <Icon name="percent" />
                        Estatisticas
                      </Menu.Item>
                    </Link>
                  )}

                  {["administrador", "root"].includes(currentRole) && (
                    <Dropdown
                      item
                      direction="right"
                      simple
                      text="Configurações"
                    >
                      <Dropdown.Menu
                        style={{ marginTop: "-0.1em", color: "black" }}
                      >
                        <Link to={routes.PRAZOS}>
                          <Dropdown.Item style={{ color: "black" }}>
                            <Icon circular inverted name="hourglass half" />
                            Prazos
                          </Dropdown.Item>
                        </Link>
                        <Link to={routes.ENTREGADORES_MANUAIS}>
                          <Dropdown.Item style={{ color: "black" }}>
                            <Icon circular inverted name="shipping fast" />
                            Entregadores Manuais
                          </Dropdown.Item>
                        </Link>
                        <Link to={routes.CONDOMINIOS}>
                          <Dropdown.Item style={{ color: "black" }}>
                            <Icon circular inverted name="university" />
                            Condomínios
                          </Dropdown.Item>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  {["administrador", "root"].includes(currentRole) && (
                    <Dropdown item direction="right" simple text="Relatórios">
                      <Dropdown.Menu
                        style={{ marginTop: "-0.1em", color: "black" }}
                      >
                        <Link to={routes.RELATORIO_PRODUCAO}>
                          <Dropdown.Item style={{ color: "black" }}>
                            <Icon circular inverted name="table" />
                            Produção
                          </Dropdown.Item>
                        </Link>
                        <Link to={routes.RELATORIO_ENVIOS}>
                          <Dropdown.Item style={{ color: "black" }}>
                            <Icon circular inverted name="group" />
                            Envios por consultor
                          </Dropdown.Item>
                        </Link>
                        <Link to={routes.PROTOCOLO}>
                          <Dropdown.Item style={{ color: "black" }}>
                            <Icon circular inverted name="file pdf outline" />
                            Protocolo
                          </Dropdown.Item>
                        </Link>

                        <Link to={routes.RELATORIO_INADIMPLENCIA}>
                          <Dropdown.Item style={{ color: "black" }}>
                            <Icon circular inverted name="file pdf outline" />
                            Inadimplência
                          </Dropdown.Item>
                        </Link>
                        {["root"].includes(currentRole) && (
                          <>
                            <Link to={routes.RELATORIO_PRODUCAO_ARQUIVOS}>
                              <Dropdown.Item style={{ color: "black" }}>
                                <Icon circular inverted name="table" />
                                Produção (Arquivo)
                              </Dropdown.Item>
                            </Link>
                          </>
                        )}

                        {["administrador", "root"].includes(currentRole) && (
                          <>
                            <Link to={routes.RELATORIO_BOLETOS_ARQUIVO}>
                              <Dropdown.Item style={{ color: "black" }}>
                                <Icon circular inverted name="barcode" />
                                Boletos (Arquivo)
                              </Dropdown.Item>
                            </Link>
                            <Link to={routes.CONSULTA_INADIMPLENCIAS}>
                              <Dropdown.Item style={{ color: "black" }}>
                                <Icon circular inverted name="search" />
                                Consulta de Inadimplências
                              </Dropdown.Item>
                            </Link>
                          </>
                        )}

                        {["administrador", "root"].includes(currentRole) && (
                          <Link to={routes.RELATORIO_GERACAO_DOCS}>
                            <Dropdown.Item style={{ color: "black" }}>
                              <Icon circular inverted name="search" />
                              Consulta de Geração de Docs
                            </Dropdown.Item>
                          </Link>
                        )}

                        {["administrador", "root"].includes(currentRole) && (
                          <Link to={routes.RELATORIO_GERACAO_CARTAS}>
                            <Dropdown.Item style={{ color: "black" }}>
                              <Icon circular inverted name="search" />
                              Consulta de Geração de Cartas
                            </Dropdown.Item>
                          </Link>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Container>
              )}

              {["assessor"].includes(currentRole) && (
                <Container fluid>
                  <Dropdown item direction="right" simple text="Relatórios">
                    <Dropdown.Menu
                      style={{ marginTop: "-0.1em", color: "black" }}
                    >
                      <Link to={routes.RELATORIO_GERACAO_DOCS}>
                        <Dropdown.Item style={{ color: "black" }}>
                          <Icon circular inverted name="search" />
                          Consulta de Geração de Docs
                        </Dropdown.Item>
                      </Link>

                      <Link to={routes.RELATORIO_GERACAO_CARTAS}>
                        <Dropdown.Item style={{ color: "black" }}>
                          <Icon circular inverted name="search" />
                          Consulta de Geração de Cartas
                        </Dropdown.Item>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </Container>
              )}

              <Menu.Menu position="right">
                <Menu.Item position="right">
                  <span style={{ textAlign: "right" }}>
                    {usuario.data.name}
                  </span>
                </Menu.Item>
                <Menu.Item
                  as="a"
                  onClick={this.props.logout}
                  content="Sair"
                  icon="sign out"
                  position="right"
                />
              </Menu.Menu>
            </Menu>
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  navigate = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { children, logout } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          className="hide-print"
        >
          <Menu.Item onClick={() => logout()} as="a">
            <Icon name="sign out" />
            Sair
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment textAlign="center" style={{ padding: "1em 0em" }} vertical>
            <Container>
              <Menu color={"violet"} pointing size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <img
                  alt="teste"
                  src={`${process.env.PUBLIC_URL}/images/logo-alt.png`}
                  style={{
                    maxHeight: 50,
                    objectFit: "contain",
                    padding: ".5em",
                  }}
                />
                <Menu.Item position="right"></Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

const ResponsiveContainer = ({
  children,
  history,
  auth: usuario,
  logout,
  ...others
}) => {
  /*const logout = () => {
    AuthApi.signout();
    history.push(ROUTES.LOGIN);
  };*/

  return (
    <>
      <div className="content-wrapper-b">
        <DesktopContainer
          className="hide-print"
          history={history}
          usuario={usuario}
          logout={logout}
          {...others}
        >
          {children}
        </DesktopContainer>
        <MobileContainer
          className="hide-print"
          history={history}
          usuario={usuario}
          logout={logout}
          {...others}
        >
          {children}
        </MobileContainer>
        <div className="push" />
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: ActionTypes.AUTH.LOGOUT }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResponsiveContainer));
